import { ScmAuth } from '@backstage/integration-react';
import { CatalogClient } from '@backstage/catalog-client';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  AnyApiFactory,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: catalogApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) => new CatalogClient({ discoveryApi, fetchApi }),
  }),
  ScmAuth.createDefaultApiFactory(),
];
