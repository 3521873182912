import React, { FC } from 'react';
import { useStyles } from './styles';

export const PostmanIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg
      data-testid="postman-icon"
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
    >
      <g clipPath="url(#a)">
        <path
          className={classes.primaryPath}
          d="M9.018.066a8 8 0 1 0-2.036 15.868A8 8 0 0 0 9.018.066Zm1.647 4.99a.57.57 0 0 0-.395.167L7.3 8.19l-.204-.204-.43-.43c2.927-2.916 3.454-2.944 3.998-2.501Zm-3.242 3.24 2.96-2.96a.414.414 0 1 1 .565.603l-3.133 2.75-.392-.392Zm.22.463-.733.159a.04.04 0 0 1-.045-.021.04.04 0 0 1 .007-.05l.43-.43.341.342Zm-1.868-.306.78-.781.587.585-1.32.284a.05.05 0 0 1-.056-.026.048.048 0 0 1 .009-.062Zm-2.431 4.039a.051.051 0 0 1-.044-.036.05.05 0 0 1-.002-.02.051.051 0 0 1 .015-.03l.632-.63.814.814-1.415-.098Zm1.617-.838a.153.153 0 0 0-.078.171l.135.577a.083.083 0 0 1-.14.078h-.003l-.622-.623-.196-.196 2.508-2.506 1.213-.262.583.583c-.837.734-1.981 1.467-3.4 2.178ZM8.48 9.37h-.001l-.56-.56 3.133-2.75a.633.633 0 0 0 .08-.084c-.1.897-1.353 2.164-2.652 3.394Zm2.438-4.306-.002-.002a1.215 1.215 0 0 1 1.64-1.79l-1.074 1.076a.078.078 0 0 0-.024.057.08.08 0 0 0 .024.056l.831.831a1.211 1.211 0 0 1-1.395-.228Zm1.719 0a1.145 1.145 0 0 1-.181.145l-.805-.805 1.022-1.022c.44.48.424 1.222-.036 1.682Zm-.067-1.03a.095.095 0 0 0-.035.105.277.277 0 0 1-.036.3.093.093 0 0 0-.008.103.093.093 0 0 0 .08.048.093.093 0 0 0 .07-.033.46.46 0 0 0 .058-.5.092.092 0 0 0-.13-.023Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path className={classes.secondaryPath} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
