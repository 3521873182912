import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  key: {
    display: 'block',
    padding: theme.spacing(2),
    overflow: 'auto',
    borderRadius: 4,
    color: theme.palette.primary.contrastText,
    backgroundColor: '#323F4B',
    fontFamily: '"Courier New", Courier, monospace',
    fontSize: 14,
    fontWeight: 700,
  },
}));

