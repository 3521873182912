import React, { FC } from 'react';
import { useStyles } from './styles';

export const DownloadIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg
      data-testid="download-icon"
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
    >
      <g clipPath="url(#a)">
        <path 
          className={classes.primaryPath}
          fill="#3C4150"
          d="M8.16 0.839844C12.648 0.839844 16.32 4.51184 16.32 8.99984C16.32 13.4878 12.648 17.1598 8.16 17.1598C3.672 17.1598 0 13.4878 0 8.99984C0 4.51184 3.672 0.839844 8.16 0.839844ZM4.896 13.0798H11.424V11.4478H4.896V13.0798ZM11.424 7.36784H9.384V4.10384H6.936V7.36784H4.896L8.16 10.6318L11.424 7.36784Z" 
        />
      </g>
      <defs>
        <clipPath id="a">
          <path className={classes.secondaryPath} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
