import React, { FC, PropsWithChildren } from 'react';
import { Box } from "@material-ui/core";
import defaultImage from '../../assets/hero-teaser/hero-teaser-default.jpg';
import { useStyles } from './styles';

export interface HeroTeaserProps {
  image?: string,
  height?: string | number
}

// TODO TBD is we do not need it anymore
export const HeroTeaser: FC<PropsWithChildren<HeroTeaserProps>> = ({ image = defaultImage, height = 354, children }) => {
  const classes = useStyles({ image, height });

  return (
    <Box
      className={classes.hero}
      px={{ xs: 4, sm: 12 }}
      py={{ xs: 12, sm: 14 }}
    >
      {children}
    </Box>
  )
}