import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  table: {
    width: 'fit-content',
  },
  tableCellBody: {
    paddingTop: 0,
    paddingBottom: 0,
  }
});
