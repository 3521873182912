import { discoveryApiRef, useApi } from "@backstage/core-plugin-api";
import { useCoreApi } from "../useCoreApi";
import { AxiosRequestConfig } from 'axios';

export const useEAPIAssetsApi = () => {
    const { doGet } = useCoreApi();
    const discoveryApi = useApi(discoveryApiRef);

    const fetchFile = async <T,>(containerName: string, filePath: string) => {
        const customReqConfig: AxiosRequestConfig = {};
        const fileExtension = filePath.split('.').pop();

        if (fileExtension !== 'json' && fileExtension !== 'html') {
            customReqConfig.responseType = 'blob';
        }

        try {
            const url = await discoveryApi.getBaseUrl(`eapi-assets/${containerName}/${filePath}`);
            const { data } = await doGet<T>(url, customReqConfig);

            return data;
        } catch (error) {
            console.error('Get file call error :: ', error instanceof Error && error.message);
            throw error;
        }
    }

    return { fetchFile }
}