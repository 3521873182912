import React, { FC, PropsWithChildren } from 'react';
import { Box, Typography, Container, Link, Button } from '@material-ui/core';
import { HeroTeaser } from '../../components/HeroTeaser';
import LaunchIcon from '@material-ui/icons/Launch';
import { useStyles } from './styles';

export interface PageProps {
  title?: string;
  subtitle?: string;
  description?: string;
  externalWebPageLink?: string;
  heroTeaserImage?: string;
  heroTeaserHeight?: string | number;
  withHeroTeaser?: boolean;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({
  withHeroTeaser = false,
  title,
  subtitle,
  description,
  externalWebPageLink,
  heroTeaserHeight,
  heroTeaserImage,
  children,
}) => {
  const classes = useStyles({ title, subtitle, description });
  const isPageInfo = !!(title || subtitle || description);

  return (
    <Box flex="1 0 auto" mt={withHeroTeaser ? 0 : 23} mb={9}>
      {withHeroTeaser && (
        <HeroTeaser image={heroTeaserImage} height={heroTeaserHeight} />
      )}
      <Container maxWidth="xl">
        {isPageInfo && (
          <Box mb={5}>
            <Box display="flex" gridColumnGap={20}>
              {title && (
                <Typography className={classes.title} variant="h3">
                  {title}
                </Typography>
              )}
              {externalWebPageLink && (
                <Button
                  component={Link}
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  href={externalWebPageLink}
                  endIcon={<LaunchIcon />}
                >
                  Visit the product webpage
                </Button>
              )}
            </Box>
            {subtitle && (
              <Typography className={classes.subtitle} variant="h4">
                {subtitle}
              </Typography>
            )}
            {description && <Typography className={classes.description}>{description}</Typography>}
          </Box>
        )}
        {children}
      </Container>
    </Box>
  );
};
