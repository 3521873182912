import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 'bold',
  },
  icon: {
    color: theme.palette.grey[600],
  },
  link: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
    fontWeight: 400,
  },
}));
