import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  table: {
    width: 'fit-content',

    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  cell: {
    width: 300,
  },
});