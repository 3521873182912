export enum FieldLabels {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  BUSINESS_EMAIL = 'Business email',
}

export enum FieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BUSINESS_EMAIL = 'businessEmail',
  APIS = 'apis',
}

export interface SignUpFormData {
  [FieldNames.FIRST_NAME]: string;
  [FieldNames.LAST_NAME]: string;
  [FieldNames.BUSINESS_EMAIL]: string;
  [FieldNames.APIS]: { [key: string]: boolean };
}

export interface UserData extends Omit<SignUpFormData, FieldNames.APIS> {
  [FieldNames.APIS]: string[];
}
