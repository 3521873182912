import { Entity } from "@backstage/catalog-model";
import { UserValidator } from "@internal/plugin-eapi-common";

export interface CanEditOptions {
  canCatalogCreate: boolean;
  userEmail?: string;
  entity?: Entity;
}

export const canEditEntity = (options: CanEditOptions): boolean => {
    const { canCatalogCreate, userEmail, entity } = options;
    if (!(userEmail && entity)) return false;

    const userValidator = new UserValidator();
    const ownerEmail = entity?.metadata.annotations?.['swissre.com/owner-email'] as string;
    const deputyEmail = entity?.metadata.annotations?.['swissre.com/deputy-email'] as string;

    const isUserOwnerOrDeputy = userValidator.isValid(ownerEmail, userEmail) || userValidator.isValid(deputyEmail, userEmail)
  
    return (canCatalogCreate && isUserOwnerOrDeputy)
}