import React, { memo, FC } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AlertTitle from '@mui/material/AlertTitle';
import { useStyles } from './styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
export interface PropsBase {
  variant: 'base';
  message: string;
}

export interface PropsMultiline {
  variant: 'multiline';
  message: string[];
  title?: string;
}

type AlertBarProps = PropsBase | PropsMultiline;

export const AlertBar: FC<AlertBarProps> = memo((props) => {
  const { variant, message } = props;
  const classes = useStyles();
  switch (variant) {
    case 'base':
      return (
        <Alert icon={<InfoOutlinedIcon className={classes.icon} />} className={`${classes.alert} ${classes.flex}`}>
          {message}
        </Alert>
      );
    case 'multiline':
      const { title } = props;
      return (
        <Alert icon={<InfoOutlinedIcon className={classes.icon} />} className={`${classes.alert} ${classes.multiline}`}>
          {title && <AlertTitle>{title}</AlertTitle>}
          <List disablePadding>
            {message.map((item: string, index) => (
              <ListItem key={`${index}-${item}`} disableGutters className={classes.listItem}>
                <ListItemIcon className={classes.bullet}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                {item}
              </ListItem>
            ))}
          </List>
        </Alert>
      );
  }
});

AlertBar.displayName = 'AlertBar';
