import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { SignInButton } from '../../components/SignInButton';
import { Navigate } from 'react-router';
import { Page } from '../../layout/Page';
import { useStyles } from './styles';
import { LogOutModeType } from '../../constants/auth';

export const SignedOut: FC = () => {
  const classes = useStyles();

  const mode = window.localStorage.getItem('eapi-logout-mode');
  const isAutoMode = mode && mode === LogOutModeType.AUTO;
  window.localStorage.clear();
  if (isAutoMode) {
    return (
      <Page>
        <Box textAlign="center">
          <Typography variant="overline">Timeout notification</Typography>
          <Typography variant="h3">You have been signed out</Typography>
          <Typography>You were automatically signed out after a period of inactivity on the Developer Portal.</Typography>
          <SignInButton className={classes.button} variant="contained">
            Log back in
          </SignInButton>
        </Box>
      </Page>
    );
  } else {
    return <Navigate to="/" />;
  }
};