import React, { useEffect, useState, useCallback, MouseEvent } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { useAsync } from 'react-use';
import { Box, LinearProgress } from '@material-ui/core';
import { BooleanConfigType, Subscription } from '@internal/plugin-eapi-common';
import { Page } from '../../layout/Page';
import { ImageMessage } from '../../components/ImageMessage';
import { SubscriptionPicker } from '../../components/SubscriptionPicker';
import { ToggleButton, ToggleButtonValue } from './components/ToggleButton';
import { useNestedPath, useSubscriptionApi } from '../../hooks';
import { SubscriptionChip } from '../../components/SubscriptionChip';
import { ApiActivity } from './components/ApiActivity';
import { ApiConsumption } from './components/ApiConsumption';
import { Routes as RoutesPath } from '../../constants/routes';

const TOGGLE_BUTTON_DEFAULT_VALUE: ToggleButtonValue = ToggleButtonValue.CONSUMPTION;

export const ApiAnalytics = () => {
  const navigate = useNavigate();
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [toggleButtonValue, setToggleButtonValue] = useState<ToggleButtonValue>(TOGGLE_BUTTON_DEFAULT_VALUE);
  const subscriptionsApi = useSubscriptionApi();
  const { nestedPathValue } = useNestedPath();

  const pageTitle = 'API Analytics';
  const pageSubtitle = 'Stay up to date and get an overview of your past and current API activity';

  const {
    value: fetchedSubscription,
    loading,
    error,
  } = useAsync(async () => {
    if (!selectedSubscription?.clientId) return;

    const subscription = await subscriptionsApi.getSubscriptionByClientId(selectedSubscription.clientId);
    const hasApiConsumptionAnnotation = subscription.services?.some(
      (service) => service.metadata.annotations?.['swissre.com/api-consumption'] === BooleanConfigType.ENABLED,
    );

    return {
      hasApiConsumption: hasApiConsumptionAnnotation,
      clientId: subscription.subscriptionDetails.clientId,
      isTrial: subscription.subscriptionDetails.isTrial,
      environmentType: subscription.subscriptionDetails.environmentType,
      name: subscription.subscriptionDetails.name,
    };
  }, [selectedSubscription?.clientId]);

  // effect needed for keeping the toggle button in sync with the url path
  // in case if user navigates with the url path or browser back/forward button
  useEffect(() => {
    if (!nestedPathValue || toggleButtonValue === nestedPathValue || !fetchedSubscription?.hasApiConsumption) return;

    setToggleButtonValue(nestedPathValue as ToggleButtonValue);
  }, [nestedPathValue, toggleButtonValue, fetchedSubscription?.hasApiConsumption, setToggleButtonValue]);

  useEffect(() => {
    if (!selectedSubscription?.clientId || fetchedSubscription?.clientId !== selectedSubscription.clientId) return;

    navigate(
      `${selectedSubscription.clientId}/${
        fetchedSubscription.hasApiConsumption ? ToggleButtonValue.CONSUMPTION : ToggleButtonValue.ACTIVITY
      }`,
      {
        replace: true,
      },
    );
  }, [fetchedSubscription?.hasApiConsumption, fetchedSubscription?.clientId, selectedSubscription?.clientId]);

  const handleToggleButtonChange = useCallback(
    (_: MouseEvent<HTMLElement>, newValue: ToggleButtonValue) => {
      if (newValue === null || !selectedSubscription?.clientId) return;

      setToggleButtonValue(newValue);
      navigate(`${selectedSubscription.clientId}/${newValue}`);
    },
    [selectedSubscription?.clientId, navigate, setToggleButtonValue],
  );

  if (error) {
    return (
      <Page title={pageTitle} subtitle={pageSubtitle}>
        <SubscriptionPicker onChange={setSelectedSubscription} />
        <ImageMessage title="Failed to load the details of this subscription." type="error" />
      </Page>
    );
  }

  if (loading || !selectedSubscription?.clientId || fetchedSubscription?.clientId !== selectedSubscription?.clientId)
    return (
      <Page title={pageTitle} subtitle={pageSubtitle}>
        <SubscriptionPicker onChange={setSelectedSubscription} />
        {loading && (
          <Box mt={4}>
            <LinearProgress data-testid="linear-progress" />
          </Box>
        )}
      </Page>
    );

  return (
    <Page title={pageTitle} subtitle={pageSubtitle}>
      <SubscriptionPicker onChange={setSelectedSubscription} />
      <>
        {fetchedSubscription?.hasApiConsumption && (
          <Box display="flex" justifyContent="center" mt={4}>
            <ToggleButton onChange={handleToggleButtonChange} value={toggleButtonValue} />
          </Box>
        )}
        <Box my={4}>
          <SubscriptionChip
            isTrial={fetchedSubscription.isTrial}
            environmentType={fetchedSubscription.environmentType}
            subscriptionName={fetchedSubscription.name}
          />
        </Box>
      </>
      <Routes>
        <Route path={RoutesPath.API_ACTIVITY} element={<ApiActivity />} />
        <Route
          path={RoutesPath.API_CONSUMPTION}
          element={<ApiConsumption isTrial={fetchedSubscription.isTrial} environmentType={fetchedSubscription.environmentType} />}
        />
      </Routes>
    </Page>
  );
};
