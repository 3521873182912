import { TableColumn } from '@backstage/core-components';
import { ApiHitActivity } from '@internal/plugin-eapi-common';
import { formatDateToUTC } from '@internal/plugin-eapi-react';
import { Options } from '@material-table/core';

export const columns: TableColumn<ApiHitActivity>[] = [
  {
    field: 'timestamp',
    title: 'Date & Time (UTC)',
    render: ({ timestamp }) => formatDateToUTC(timestamp),
  },
  { field: 'apiName', title: 'API Name', cellStyle: { minWidth: '200px', } },
  { field: 'requestMethod', title: 'Method', width: 'auto' },
  { field: 'clientRequestPath', title: 'Request Path' },
  { field: 'edgeResponseStatus', title: 'Status' },
  { field: 'apmId', title: 'APM' },
  { field: 'clientIP', title: 'Client IP Address' },
  { field: 'clientRequestHost', title: 'Client Request Host' },
  { field: 'clientRequestURI', title: 'Client Request URI' },
  { field: 'contentType', title: 'Content Type' },
  { field: 'clientId', title: 'Consumer Id', },
];

export const options: Options<ApiHitActivity> = {
  paging: true,
  pageSize: 20,
  headerStyle: { textTransform: 'none', whiteSpace: 'nowrap', borderTop: 'none' },
  rowStyle: (_, index) => ({
    backgroundColor: index % 2 === 0 ? '#F9F9FA' : '#FFFFFF',
    verticalAlign: 'top',
  }),
};