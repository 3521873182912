import React, {FC} from 'react';
import {makeStyles, Theme} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  svg:{
    width: 21,
    height: 21,
    fill: 'none'
  },
  path: {
    fill: theme.palette.grey[600],
  },
}));

const Linkedin: FC = () => {
  const classes = useStyles();

  return (
      <svg
          className={classes.svg}
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            className={classes.path}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.477 4.954A2.45 2.45 0 0 1 0 2.477 2.45 2.45 0 0 1 2.477 0a2.45 2.45 0 0 1 2.477 2.477c0 1.329-1.087 2.477-2.477 2.477Zm2.113 15.71H.3V6.827h4.29v13.835Zm11.841-.002h4.23V13.05c0-3.746-.786-6.586-5.136-6.586-2.054 0-3.504 1.148-4.048 2.236h-.06V6.827H7.309v13.835h4.29v-6.827c0-1.812.362-3.564 2.597-3.564 2.236 0 2.236 2.054 2.236 3.685v6.706Z"
        />
      </svg>
  );
};

export default Linkedin;