import { Theme, makeStyles } from '@material-ui/core';
import { KeyStatusProps } from './KeyStatus';
import { KeyStatus } from '@internal/plugin-eapi-common';

export const useStyles = makeStyles<Theme, KeyStatusProps>((theme) => ({
  indicator: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: ({ status }) => (status === KeyStatus.Active ? theme.palette.success.main : theme.palette.warning.main),
  },
}));