import * as yup from 'yup';
import { FieldLabels, FieldNames } from './constants';
import { EMAIL_REGEX, getErrorMessage, NAME_REGEX, NO_HTML_REGEX } from '../../../constants/validation';


export const schema = yup.object().shape({
  [FieldNames.FIRST_NAME]: yup
    .string()
    .required(getErrorMessage(FieldLabels.FIRST_NAME, 'required'))
    .min(2, getErrorMessage(FieldLabels.FIRST_NAME, 'min'))
    .max(50, getErrorMessage(FieldLabels.FIRST_NAME, 'max'))
    .matches(NAME_REGEX, getErrorMessage(FieldLabels.FIRST_NAME, 'name'))
    .matches(NO_HTML_REGEX, getErrorMessage(FieldLabels.FIRST_NAME, 'html')
    )
    .transform((value) => value.trim()),
  [FieldNames.LAST_NAME]: yup
    .string()
    .required(getErrorMessage(FieldLabels.LAST_NAME, 'required'))
    .min(2, getErrorMessage(FieldLabels.LAST_NAME, 'min'))
    .max(50, getErrorMessage(FieldLabels.LAST_NAME, 'max'))
    .matches(NAME_REGEX, getErrorMessage(FieldLabels.LAST_NAME, 'name'))
    .matches(NO_HTML_REGEX, getErrorMessage(FieldLabels.LAST_NAME, 'html')
    )
    .transform((value) => value.trim()),
  [FieldNames.BUSINESS_EMAIL]: yup
    .string()
    .required(getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'required'))
    .matches(EMAIL_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'email'))
    .matches(NO_HTML_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'html'))
    .transform((value) => value.trim()),
});
