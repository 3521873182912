import React, { FC } from 'react';
import { useStyles } from './styles';

export const BookIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg data-testid="download-icon" className={classes.svg} width="23" height="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.348 17.65c-1.35-.85-3.8-1.5-5.5-1.5-1.65 0-3.35.3-4.75 1.05-.1.05-.15.05-.25.05-.25 0-.5-.25-.5-.5V2.15c.6-.45 1.25-.75 2-1 1.11-.35 2.33-.5 3.5-.5 1.95 0 4.05.4 5.5 1.5 1.45-1.1 3.55-1.5 5.5-1.5 1.17 0 2.39.15 3.5.5.75.25 1.4.55 2 1v14.6c0 .25-.25.5-.5.5-.1 0-.15 0-.25-.05-1.4-.75-3.1-1.05-4.75-1.05-1.7 0-4.15.65-5.5 1.5Zm-1-14c-1.36-.6-3.16-1-4.5-1-1.2 0-2.4.15-3.5.5v11.5c1.1-.35 2.3-.5 3.5-.5 1.34 0 3.14.4 4.5 1V3.65Zm2 11.5c1.36-.6 3.16-1 4.5-1 1.2 0 2.4.15 3.5.5V3.15c-1.1-.35-2.3-.5-3.5-.5-1.34 0-3.14.4-4.5 1v11.5Z"
        fill="#3C4150"
      />
    </svg>
  );
};
