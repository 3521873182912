import { handleError } from "../../../api/error/handleError";
import { useAxiosPrivate } from "../../useAxiosPrivate";
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const useCoreApi = () => {
    const axiosPrivate = useAxiosPrivate();

    const doGet = async <T,>(url: string, customReqConfig?: AxiosRequestConfig): Promise<AxiosResponse<T> | never> => {
        try {
            return await axiosPrivate.get(decodeURIComponent(url), customReqConfig);
        } catch (error) {
            handleError(error);
        }
    }

    const doPost = async <T,>(url: string, body: unknown, customReqConfig?: AxiosRequestConfig): Promise<AxiosResponse<T> | never> => {
        try {
            const response = await axiosPrivate.post(decodeURIComponent(url), body, customReqConfig);
            return response;
        } catch (error) {
            handleError(error);
        }
    }

    const doPut = async <T,>(url: string, body: unknown, customReqConfig?: AxiosRequestConfig): Promise<AxiosResponse<T> | never> => {
        try {
            const response = await axiosPrivate.put(decodeURIComponent(url), body, customReqConfig);
            return response;
        } catch (error) {
            handleError(error);
        }
    }

    const doDelete = async <T,>(url: string, data: unknown): Promise<AxiosResponse<T> | never> => {
        try {
            const response = await axiosPrivate.delete(decodeURIComponent(url), { data });
            return response;
        } catch (error) {
            handleError(error);
        }
    }

    return { doGet, doPost, doPut, doDelete }
}