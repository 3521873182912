import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { EmailValidatorExtension } from './EmailValidatorExtension';
import { emailPickerValidation } from './validation';

export const EmailValidatorFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EmailValidatorExtension',
    component: EmailValidatorExtension,
    validation: emailPickerValidation,
  }),
);