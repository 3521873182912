import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  sectionSubtitleContainer: {
    marginBottom: theme.spacing(4),
  },
  button: {
    display: 'block',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '4px',
    boxShadow: '0px 8px 12px 0px rgba(60, 65, 80, 0.04)',

    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },

    '&.Mui-selected': {
      border: `1px solid ${theme.palette.primary.dark}`,
      boxShadow: `4px 3px 8.9px 1px ${alpha(theme.palette.primary.dark, 0.35)}`,
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      pointerEvents: 'none',
      textAlign: 'center',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0',

      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  image: {
    display: 'block',
    width: '100%',
  },
}));
