import React, {memo, FC, SyntheticEvent, MouseEvent} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from "@material-ui/lab";
import { useStyles } from './styles';
export interface NotificationSnackBarProps {
  open: boolean,
  onClose: () => void
  message: string | JSX.Element,
  type: 'success' | 'info' | 'warning' | 'error'
  variant?: 'standard' | 'filled' | 'outlined'
  isMultilineMsg?: boolean
}

export const NotificationSnackBar: FC<NotificationSnackBarProps> = memo(({open, onClose, message, type, variant, isMultilineMsg = false}) => {
  const classes = useStyles();
  const handleClose = (_: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  if(!open) return null

  return (
      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
          }
      >
        <Alert className={isMultilineMsg ? classes.action : ''} variant={variant} onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
  )
});

NotificationSnackBar.displayName = "NotificationSnackBar";