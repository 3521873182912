import React, { FC, useState } from 'react';
import { useAsync } from 'react-use';
import { useParams, useSearchParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { ContainerName } from '@internal/plugin-eapi-common';
import { EAPITechDocsMetadata, extractMetadata } from '@internal/plugin-eapi-react';
import { useEAPIAssetsApi } from '../../hooks';
import { ImageMessage } from '../ImageMessage';
import { TechDocsHTML } from '../TechDocsHTML';

export const ServiceDocumentationTab: FC = () => {
  const eapiAssetsApi = useEAPIAssetsApi();
  const [techDocsMetadata, setTechDocsMetadata] = useState<EAPITechDocsMetadata>();

  const { solutionName }= useParams();
  const [searchParams] = useSearchParams();
  const docType = searchParams.get('docType');

  const {
    value: content,
    loading,
    error,
  } = useAsync(async () => {
    const name = docType ? `${solutionName}-${docType}` : solutionName;
    const content = await eapiAssetsApi.fetchFile<string>(ContainerName.EAPI_TECH_DOCS, `default/system/${name}/index.html`);
    const {cssFileName, title, description} = extractMetadata(content);

    const metadata: EAPITechDocsMetadata = {
      fileName: name || '',
      cssFileName: cssFileName || '',
      title: title || '',
      description: description || ''
    };
    
    setTechDocsMetadata(metadata);
    return content
  }, [solutionName, docType]);

  if (error) {
    return <ImageMessage type="error" title='Failed to load documentation.' />;
  }

  if (content?.length === 0) {
    return <ImageMessage type="info" title='You need to have a subscription to view this documentation.' />;
  }

  if (loading) return <LinearProgress />;

  if(!techDocsMetadata) return null;

  return <TechDocsHTML content={content || ''} metadata={techDocsMetadata}/>;
};
