import { makeStyles, Theme } from '@material-ui/core';
import { SectionProps } from './Section';

export const useStyles = makeStyles<Theme, Pick<SectionProps, 'isBottomBorder'>>((theme) => ({
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: ({ isBottomBorder }) => (isBottomBorder ? `1px solid ${theme.palette.divider}` : 'none'),
    },
  },
  heading: {
    marginBottom: theme.spacing(4),
  },
}));
