import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';
import { Divider, Grid, LinearProgress } from '@material-ui/core';
import { SDSEnvironmentType, Solution } from '@internal/plugin-eapi-common';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { useApiAnalyticsApi } from '../../../../hooks';
import { SolutionsFilter } from './components/SolutionsFilter';
import { ModuleInfo } from './components/ModuleInfo';
import { ImageMessage } from '../../../../components/ImageMessage';
import { AlertBar } from '../../../../components/AlertBar';
import { TimePeriodFilter, TimePeriodOption } from '../TimePeriodFilter';
import { getAlertContent } from './utils/getAlertContent';

export const DEFAULT_DATE_FROM_FILTER: TimeUnitRangeValue = 'now-15d';

export const OPTIONS: TimePeriodOption<TimeUnitRangeValue>[] = [
  { name: 'Last 7 days', value: 'now-7d' },
  { name: 'Last 15 days', value: 'now-15d' },
  { name: 'Last 30 days', value: 'now-30d' },
];

interface ApiConsumptionProps {
  environmentType: SDSEnvironmentType;
  isTrial?: boolean;
}

export const ApiConsumption: FC<ApiConsumptionProps> = ({ environmentType, isTrial }) => {
  const apiAnalyticsApi = useApiAnalyticsApi();
  const { clientId } = useParams();
  const [selectedSolution, setSelectedSolution] = useState<Solution>();
  const [timePeriodFilter, setTimePeriodFilter] = useState<TimeUnitRangeValue>(DEFAULT_DATE_FROM_FILTER);

  const {
    value: solutions,
    loading,
    error,
  }: { value?: Solution[]; loading: boolean; error?: Error } = useAsync(async () => {
    if (!clientId) return;

    const solutions = await apiAnalyticsApi.getApiConsumption(clientId, timePeriodFilter);
    setSelectedSolution(solutions[0]);

    return solutions;
  }, [clientId, timePeriodFilter]);

  const { title, message } = getAlertContent(environmentType, isTrial, selectedSolution);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TimePeriodFilter defaultValue={DEFAULT_DATE_FROM_FILTER} setFilter={setTimePeriodFilter} options={OPTIONS} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {!loading && solutions && selectedSolution && (
          <SolutionsFilter solutions={solutions} selectedSolution={selectedSolution} onSolutionChange={setSelectedSolution} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
        {loading && <LinearProgress data-testid="linear-progress" />}
      </Grid>
      {!loading && selectedSolution && !!solutions?.length && (
        <>
          <Grid item xs={12}>
            <AlertBar variant="multiline" title={title} message={message} />
          </Grid>
          {selectedSolution.modules.map((module) => (
            <Grid item key={module.name}>
              <ModuleInfo module={module} period={timePeriodFilter} />
            </Grid>
          ))}
        </>
      )}
      {!loading && (error || solutions?.length === 0) && (
        <Grid item xs={12}>
          <ImageMessage
            type={error ? 'error' : 'info'}
            title={error ? 'Failed to load API consumption information.' : 'API consumption information is not available.'}
          />
        </Grid>
      )}
    </Grid>
  );
};
