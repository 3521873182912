export enum BrowserType {
  Edge = 'Edge',
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera'
}

type Browser = BrowserType | 'Unknown'

export function getBrowserType(): Browser {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Edg') > -1) {
    return BrowserType.Edge;
  }
  if (userAgent.indexOf(BrowserType.Chrome) > -1 && userAgent.indexOf(BrowserType.Safari) > -1 && userAgent.indexOf(BrowserType.Edge) === -1) {
    return BrowserType.Chrome;
  }
  if (userAgent.indexOf(BrowserType.Firefox) > -1) {
    return BrowserType.Firefox;
  }
  if (userAgent.indexOf(BrowserType.Safari) > -1 && userAgent.indexOf(BrowserType.Chrome) === -1) {
    return BrowserType.Safari;
  }
  if (userAgent.indexOf(BrowserType.Opera) > -1 || userAgent.indexOf('OPR') > -1) {
    return BrowserType.Opera;
  }
  return 'Unknown';
}