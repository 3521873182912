import { makeStyles } from '@material-ui/core';
import bgContactUs from '../../assets/bg-contact-us.png';

export const useStyles = makeStyles(theme => ({
  contacts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url(${bgContactUs})`,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  devPortalLink: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    '&:hover': {
      color: theme.palette.grey[100],
    },
  },
}));
