import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useAsync } from 'react-use';
import { LinearProgress } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { ImageMessage } from '../../components/ImageMessage';
import { useEAPIAssetsApi } from '../../hooks';
import { ContainerName } from '@internal/plugin-eapi-common';
import { TechDocsHTML } from '../../components/TechDocsHTML';
import { EAPITechDocsMetadata, extractMetadata } from '@internal/plugin-eapi-react';

export const Guide = () => {
  const { pathname } = useLocation();
  const eapiAssetsApi = useEAPIAssetsApi();
  const [techDocsMetadata, setTechDocsMetadata] = useState<EAPITechDocsMetadata>();

  const {
    value: content,
    loading,
    error,
  } = useAsync(async () => {
    const name = `eapi-${pathname.split('/').pop()}`;
    const content = await eapiAssetsApi.fetchFile<string>(ContainerName.EAPI_TECH_DOCS, `default/system/${name}/index.html`);
    const {cssFileName, title, description} = extractMetadata(content);
    
    const metadata: EAPITechDocsMetadata = {
      fileName: name || '',
      cssFileName: cssFileName || '',
      title: title || '',
      description: description || ''
    };
    
    setTechDocsMetadata(metadata);
    return content;
  }, [pathname]);

  if (error) {
    return (
      <Page>
        <ImageMessage type="error" title="Failed to load guide." />
      </Page>
    );
  }

  return (
    <Page
     title={techDocsMetadata?.title}
     description={techDocsMetadata?.description}>
      {loading ? (
        <LinearProgress />
      ) : (
        techDocsMetadata && <TechDocsHTML content={content || ''} metadata={techDocsMetadata}/>
      )}
    </Page>
  );
};
