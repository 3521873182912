import { FC } from 'react';
import { OverviewTab } from './components/OverviewTab';
import { Entity } from '@backstage/catalog-model';
import { ServiceDocumentationTab } from '../../components/ServiceDocumentationTab';

export interface PanelComponentProps {
  entity?: Entity
}

export interface TabConfig {
  panelComponent: FC<PanelComponentProps>;
  label: string;
  path: string;
}

export const TAB_LABELS = {
  OverviewTab: 'Overview',
  ServiceDocumentationTab: 'Documentation',
};

export const TAB_PATHS = {
  OverviewTab: 'overview',
  ServiceDocumentationTab: 'documentation',
};

export const TABS_CONFIGURATION: ReadonlyArray<TabConfig> = [
  {
    panelComponent: OverviewTab,
    label: TAB_LABELS.OverviewTab,
    path: TAB_PATHS.OverviewTab,
  },
  {
    panelComponent: ServiceDocumentationTab,
    label: TAB_LABELS.ServiceDocumentationTab,
    path: TAB_PATHS.ServiceDocumentationTab,
  },
];
