export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const NAME_REGEX = /^[a-zA-Z\s'-]+$/;
export const NO_HTML_REGEX = /^[^<>]*$/;


type ValidationType = 'required' | 'min' | 'max' | 'email' | 'name' | 'html';

export const getErrorMessage = (label: string, type: ValidationType) => {
  switch (type) {
    case 'required':
      return `${label} is required`;
    case 'min':
      return `${label} is too short`;
    case 'max':
      return `${label} is too long`;
    case 'email':
      return `${label} must be valid email address`;
    case 'name':
      return `${label} can only contain letters, spaces, hyphens, and apostrophes`;
    case 'html':
      return `HTML tags are not allowed in the ${label}`;
    default:
      return '';
  }
}