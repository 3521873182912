import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
      boxShadow: '0px 10px 70px 0px rgba(0, 0, 0, 0.09)',
      borderRadius: '4px',
      backgroundColor: theme.palette.common.white,
      zIndex: 2,
      position: 'relative',
      textAlign: 'left',
      margin: '-100px 16px 48px',
    },
    textAlign: 'center',
    marginTop: '2rem',
    padding: `0px ${theme.spacing(6)}px`,
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
  grid: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('md')]: {
      borderBottom: 'none',
      '&.MuiGrid-item': {
        paddingRight: theme.spacing(5),
      },
      '&:last-of-type': {
        borderLeft: `1px solid ${theme.palette.divider}`,
        paddingLeft: theme.spacing(5),
        paddingRight: 0,
      },
    },
  },
  link: {
    wordBreak: 'break-word',
  },
  button: {
    [theme.breakpoints.up('md')]: {
      alignSelf: 'start',
    },    
    alignSelf: 'center',
  },
}));
