import React, { FC, useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { FieldLabels, FieldNames, PUB_KEY_PLACEHOLDER } from './constants';
import { Routes } from '../../../../../../../../../../constants/routes';
import { GuidesNames } from '../../../../../../../../../../constants/guides';
import { useGuidesList } from '../../../../../../../../../../hooks';
import { useStyles } from './styles';
import { useEncryptionApi } from '../../../../../../../../../../hooks';
import { AxiosError } from 'axios';

export interface UploadKeyFormProps {
  clientId: string;
  onCancel: () => void;
  onUploadKey: (type: 'success' | 'error', message: string) => void;
}

export interface UploadKeyFormData {
  [FieldNames.PUBLIC_KEY]: string;
}

export const UploadKeyForm: FC<UploadKeyFormProps> = memo(
  ({ onCancel, clientId, onUploadKey }) => {
    const classes = useStyles();
    const { register, handleSubmit, reset } = useForm<UploadKeyFormData>({
      mode: 'onSubmit',
    });
    const encryptionApi = useEncryptionApi();
    const [loading, setLoading] = useState<boolean>(false);
    const { guidesList } = useGuidesList();

    const handleModalClose = () => {
      reset();
      onCancel();
    };

    const onSubmit = async (formData: UploadKeyFormData) => {
      try {
        setLoading(true);

        await encryptionApi.setPublicKey(clientId, formData[FieldNames.PUBLIC_KEY]);

        onUploadKey('success', 'New public key sent successfully!');
      } catch (error) {
        console.log(error)
        const errorMessage = (error instanceof AxiosError)
          ? error.response?.data?.message
          : 'A problem has  occurred, please try again or contact the Developer Portal team if the error persists.';
        onUploadKey('error', errorMessage);
      } finally {
        setLoading(false);
        handleModalClose();
      }
    };

    const guide = guidesList?.find(
      ({ fileName }) => fileName === GuidesNames.KeyGenAuth,
    );

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          minRows={8}
          required
          {...register(FieldNames.PUBLIC_KEY)}
          label={FieldLabels.PUBLIC_KEY}
          placeholder={PUB_KEY_PLACEHOLDER}
          inputProps={{ 'aria-label': FieldLabels.PUBLIC_KEY }}
          focused
        />
        {guide && (
          <Typography className={classes.hintText} variant="body1" gutterBottom>
            If you are unsure how to generate or work with a new Public Key,
            please see our guide
            <Link
              className={classes.hintTextLink}
              href={`${Routes.GUIDES}/${guide.fileName}`}
              underline="always"
            >
              {guide.title}
            </Link>
          </Typography>
        )}
        <Box mt={4} display="flex" justifyContent="center" gridColumnGap={16}>
          <Button variant="outlined" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            startIcon={loading && <CircularProgress size={24} />}
          >
            Upload
          </Button>
        </Box>
      </form>
    );
  },
);

UploadKeyForm.displayName = 'UploadKeyForm';
