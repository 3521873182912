import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  description: {
    color: theme.palette.primary.main,
    fontWeight: 300,
    marginBottom: theme.spacing(4),
    fontSize: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  author: {
    fontWeight: 700
  },
  title:{
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(10)}px`
    },
  }
}));
