import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { FolderIcon } from '../../../../icons';
import { useStyles } from './styles';

export interface IUploadFilePlaceholderProps {
  data: string;
  isImageFile: boolean;
}

export const UploadFilePlaceholder: FC<IUploadFilePlaceholderProps> = ({ data, isImageFile }) => {
  const classes = useStyles();

  return isImageFile ? (
    <img alt="service picture" src={data} height={100} className={classes.image} />
  ) : (
    <Box bgcolor="#F8F8F8" textAlign="center" py={1} px={3}>
      <Box mx="auto" display="flex" justifyContent="center" alignItems="center" borderRadius="50%" bgcolor="#FFFFFF" width={60} height={60}>
        <FolderIcon />
      </Box>
      <Typography variant="body1" color="textSecondary">
        .zip file.
      </Typography>
    </Box>
  );
};
