import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(
  (theme) => ({
    header: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      maxWidth: 1508,
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderRadius: '4px',
      boxShadow: '0 20px 70px 0 rgb(0 0 0/10%)',

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
      [theme.breakpoints.up('xl')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    leftItemsBox: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(4),
      flexGrow: 1,
    },
    rightItemsBox: {
      width: 'auto',
    },
    logo: {
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    logoTitle: {
      marginLeft: theme.spacing(3),
      color: theme.palette.primary.main,
      fontSize: 16,
    },
    navLink: {
      fontSize: 15,
      fontWeight: 400,
    },
  }),
  { name: 'DeveloperPortalHeader' },
);