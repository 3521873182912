import React, { createContext, Dispatch, SetStateAction, useContext, FC, useState, PropsWithChildren } from 'react';

interface ILoadingContext {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

const LoadingContext = createContext<ILoadingContext>({
    loading: false,
    setLoading: () => { },
});

export const LoadingProvider: FC<PropsWithChildren> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);

    return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => useContext(LoadingContext);
