import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.grey[100]}`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',

    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  }
}));
