export const debounce = (fn: Function, delay: number) => {
    let timeOutId: ReturnType<typeof setTimeout>;
    return function(...args: any[]) {
        if(timeOutId) {
            clearTimeout(timeOutId);
        }
        timeOutId = setTimeout(() => {
            fn(...args);
        },delay);
    }
}

export const DEBOUNCE_DELAY = 400; // delay in ms
