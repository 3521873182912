import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  key: {
    padding: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: theme.palette.grey[50],
    fontSize: 14,
    fontFamily: 'Courier New, monospace',
    fontWeight: 700,
  },
  spacing: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.secondary.light
  }
}));