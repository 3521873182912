import React from 'react';
import { Container, Typography, Grid, List, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import { RESOURCES } from './constants';
import { Section } from '../../../../layout/Section';
import { useStyles } from './styles';

export const DeveloperResources = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (index: number) => () => {
    if (isMobile) return;

    setSelectedIndex(index);
  };

  return (
    <Section title="Developer resources" isBottomBorder={false}>
      <Container maxWidth="md" className={classes.sectionSubtitleContainer}>
        <Typography variant="h3" align="center">
          The Swiss Re Developer Portal enables developers to learn, collaborate, build and monitor innovative solutions.
        </Typography>
      </Container>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <List component="div">
            {RESOURCES.map(({ id, title, subTitle }, index) => (
              <ListItem
                key={id}
                button
                selected={!isMobile && selectedIndex === index}
                className={classes.button}
                onClick={handleListItemClick(index)}
              >
                <Typography variant="h5" gutterBottom>
                  {title}
                </Typography>
                <Typography variant="body1">{subTitle}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <img src={RESOURCES[selectedIndex].image} alt={RESOURCES[selectedIndex].altText} className={classes.image} loading='lazy'/>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};
