import { alpha, makeStyles } from '@material-ui/core';
import image from '../../../../assets/home/explore-further-swissre-developer-portal.jpg';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 700,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginTop: 48,
    [theme.breakpoints.down('sm')]: {
      height: 540,
    },
    backgroundColor: `${alpha(theme.palette.grey[600], 0.4)}`,
  },
  link: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },

    '&:hover': {
      color: theme.palette.grey[100],
    },
  },
  button: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.contrastText, 0.2)}`,
      borderColor: 'transparent',
    },
  },
}));
