import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      paddingTop: theme.spacing(6),
    },
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  stepper: {
    marginBottom: theme.spacing(4),
  },
  stepperLabelText: {
    fontSize: 12,
  },
}));
