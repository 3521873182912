import { Theme, makeStyles } from '@material-ui/core';
import { HeroTeaserProps } from './HeroTeaser';

export const useStyles = makeStyles<Theme, HeroTeaserProps>({
  hero: {
    height: ({ height }) => height,
    backgroundImage: ({ image }) => `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});
