import { createTheme, Theme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#627D77',
      light: '#CCE2E3',
      dark: '#006E73',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FF5630',
      light: '#FFAB00',
      dark: '#6554C0',
    },
    grey: {
      50: '#F9F9FA',
      100: '#ECECEE',
      200: '#D8D9DC',
      300: '#B1B3B9',
      400: '#636773',
      500: '#505461',
      600: '#3C4150',
      700: '#242730',
    },
    error: {
      main: '#DA5151',
      light: '#FDEDED',
      dark: '#DE350B',
    },
    warning: {
      main: '#EF7E22',
      light: '#FFF4E5',
      dark: '#FF8B00',
    },
    success: {
      main: '#519354',
      light: '#EDF7ED',
      dark: '#006644',
    },
    info: {
      main: '#1B94D6',
      light: '#E5F6FD',
      dark: '#0747A6',
    },
    text: {
      primary: '#3C4150',
      secondary: '#636363',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'SwissReSansOT, Roboto, Helvetica, Arial, sans-serif',
    fontWeightRegular: 300,
    fontWeightBold: 700,
    h1: {
      fontSize: 80,
      [createTheme().breakpoints.down('sm')]: {
        fontSize: 40,
      },
    },
    h2: {
      fontSize: 50,
      [createTheme().breakpoints.down('sm')]: {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: 30,
      [createTheme().breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    h4: {
      fontSize: 22,
      [createTheme().breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    h5: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    overline: {
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: 1.5,
    },
    button: {
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1540,
    },
  },
});

const createCustomThemeOverrides = (theme: Theme): Overrides => {
  return {
    MuiButton: {
      root: {
        borderRadius: '100px',
        padding: '12px 30px',
      },
      contained: {
        border: `1px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',

        '&:hover': {
          color: theme.palette.primary.dark,
          backgroundColor: theme.palette.background.default,
          border: `1px solid ${theme.palette.primary.light}`,
          boxShadow: 'none',
        },
      },
      outlined: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.light,
        padding: '12px 30px',

        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.dark,
          borderColor: theme.palette.primary.dark,
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow:
          '0px 56px 60px -9px rgb(60 65 80 / 6%), 0px 29.3375px 26.1562px -9px rgb(60 65 80 / 4%), 0px 12.8px 9.75px -9px rgb(60 65 80 / 3%), 0px 4.3625px 3.46875px -9px rgb(60 65 80 / 2%)',
        borderRadius: '3px',
      },
    },

    MuiCardContent: {
      root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    MuiChip: {
      root: {
        padding: theme.spacing(0.5),
        fontSize: 16,
        fontWeight: 400,
      },
      outlined:{ 
       backgroundColor: '#FFF5E5',
       color: '#75570A',
       border: 'none',
      },
      outlinedSecondary:{
        backgroundColor: '#F5E8F7',
        color: '#9B14B4',
        border: 'none',
      }
    },
    MuiLink: {
      root: {
        color: theme.palette.grey[600],
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: 18,
        textTransform: 'none',
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
  };
};

export const swissReTheme: Theme = {
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    ...createCustomThemeOverrides(baseTheme),
  },
};
