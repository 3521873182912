import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    paddingBottom: theme.spacing(4),
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
    fontSize: 16,
    fontWeight: 700,
  },
  checkboxWrapper: {
    height: '100%',
    width: '100%',
    minHeight: 250,
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[100]}`,
    position: 'relative',
    justifyContent: 'center',

    '& .MuiFormControlLabel-label': {
      height: '100%',
    },

    '& .MuiCheckbox-root': {
      position: 'absolute',
      bottom: theme.spacing(1),
    },
  },
}));
