import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useRefreshToken } from '../../useRefreshToken';

export const useCatalogApi = () => {
    const catalogApi = useApi(catalogApiRef);
    const { refresh } = useRefreshToken();

    const getEntities = async (appliedFilter: Record<string, string>) => {
        const newUserSession = await refresh();
        const newAccessToken = newUserSession?.identity.token;
        return await catalogApi.getEntities({ filter: appliedFilter }, { token: newAccessToken });
    }

    return { getEntities };
};
