import { useParams } from "react-router";

export const useNestedPath = () => {
  const params = useParams();

  const paramsArray = params['*']?.split('/');
  const clientIdParam = paramsArray?.[0];
  const nestedPathValue = paramsArray?.slice(1).join('/');
  const nestedPath = nestedPathValue ? `/${nestedPathValue}` : '';

  return { clientIdParam, nestedPath, nestedPathValue };
};