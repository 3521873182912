import React, { FC } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { Page } from '../../layout/Page';
import { Card } from '../../components/Card';
import { ImageMessage } from '../../components/ImageMessage';
import { useGuidesList } from '../../hooks';

export const Guides: FC = () => {
  const { guidesList, loading, error } = useGuidesList();

  if (error) {
    return (
      <Page>
        <ImageMessage type="error" title="Failed to load guides." />
      </Page>
    );
  }

  return (
    <Page
      title="Developer Portal Integration Guides"
      subtitle="Access tutorials for using Swiss Re APIs and integrating them into your applications"
      description="Our guides provide step-by-step instructions on the Swiss Re API offering."
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={4}>
          {(guidesList || []).map(({ fileName, title, description }) => {
            const href = `${Routes.GUIDES}/${fileName}`;

            return (
              <Grid key={fileName} item xs={12} sm={6} md={4} lg={3}>
                <Card href={href} title={title} description={description} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Page>
  );
};
