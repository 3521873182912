export interface SDSUser {
    name: string,
    role: string,
    email: string
}

export interface SDSUserSubscription {
    name: string,
    clientId: string,
    isActive: boolean,
}

export interface SDSUserSubscriptionDetails {
    startDate: string,
    endDate: string,
    connectedApis: SDSConnectedApi[],
    users: SDSUser[],
    isActive: boolean,
    clientId: string,
    authorizationType: string,
    name: string,
    isTrial?: boolean,
    environmentType: SDSEnvironmentType,
    isLegacy: boolean
}

export interface SDSConnectedApi {
    name: string;
    apmId: string;
    authorizationServerId: string;
    environmentType: SDSEnvironmentType;
    businessServiceName: string;
    clientApplications: SDSClientApplication[];
}

export interface SDSClientApplication {
    name: string;
    clientId: string;
    isActive: boolean;
    environmentType: SDSEnvironmentType;
    isLegacy: boolean;
}

export interface BusinessServiceDetails {
    entityTitle: string;
    clientApplications: SDSClientApplication[];
    entityName?: string
}

export enum SDSEnvironmentType {
    PROD = 'PRD',
    NON_PROD = 'NPD',
    DEMO = 'DEM'
}