import React, { FC, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

interface DialogContextProps {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const DialogContext = createContext<DialogContextProps>({
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return <DialogContext.Provider value={{ isOpen, handleOpen, handleClose }}>{children}</DialogContext.Provider>;
};

export const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
