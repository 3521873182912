import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, FormControl, Button, Typography } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FieldProps } from '@rjsf/utils';
import { UploadFilePlaceholder } from './components/UploadFilePlaceholder';
import { useStyles } from './styles';

interface IFileParams {
  inputAccept: string;
  fileRestrictionText: string;
}
type fileTypes = 'zip' | 'image';

const fileParams: Record<fileTypes, IFileParams> = {
  zip: {
    inputAccept: 'zip, application/zip',
    fileRestrictionText: 'Maximum size 2mb (Format .zip)',
  },
  image: {
    inputAccept: 'image/jpeg, image/png',
    fileRestrictionText: 'Maximum size 512kb (Format .jpg, .jpeg, .png)',
  },
};

export const UploadFileExtension = (props: FieldProps) => {
  const { formData, onChange, rawErrors, required, uiSchema, schema } = props;
  const classes = useStyles();
  const fileType = uiSchema?.['ui:options']?.fileType as fileTypes;
  const isImageFile = fileType === 'image';
  const { inputAccept, fileRestrictionText } = fileParams[fileType];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const files = e.target.files;

    if (!files?.length) return;

    const [file] = files;

    reader.onloadend = function () {
      onChange(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleDeleteFile = () => {
    onChange(undefined);
  };

  return (
    <>
      <FormControl required={required} error={rawErrors && rawErrors?.length > 0 && !formData} onChange={handleChange}>
        <Typography variant="body1" color="textSecondary">
          {schema.title}
          {required ? ' *' : ''}
        </Typography>
        <Typography color="textSecondary" variant="caption" gutterBottom>
          {fileRestrictionText}
        </Typography>
        <label aria-required={required} htmlFor={`input-file-${schema.title}`}>
          <input
            accept={inputAccept}
            className={classes.input}
            id={`input-file-${schema.title}`}
            type="file"
            onClick={(e: MouseEvent<HTMLInputElement>) => ((e.target as HTMLInputElement).value = '')}
          />
          <Button aria-label="upload picture" variant="contained" color="primary" startIcon={<CloudUploadIcon />} component="span">
            Upload File
          </Button>
        </label>
      </FormControl>
      {formData && (
        <Box maxWidth="fit-content" border="1px solid #D0D5DD" p={1} textAlign="center" mt={2}>
          <UploadFilePlaceholder isImageFile={isImageFile} data={formData} />
          <Button className={classes.deleteButton} aria-label="delete file" onClick={handleDeleteFile} startIcon={<DeleteOutlinedIcon />}>
            Delete
          </Button>
        </Box>
      )}
    </>
  );
};
