export const removeUnwantedElements = (container: HTMLDivElement, selectorsSet: string[]) => {
  selectorsSet.forEach((selector) => {
    const element = container.querySelector(selector);

    element && element.remove();
  });
};

export const handleAnchorClick = (event: Event, shadowRoot: ShadowRoot) => {
  const target = event.target as HTMLElement;
  const anchor = target.closest('a') as HTMLAnchorElement;

  if (anchor && anchor.getAttribute('href')?.startsWith('#')) {
    const targetId = anchor.getAttribute('href')!.substring(1);
    const targetElement = shadowRoot.getElementById(targetId);

    if (targetElement) {
      event.preventDefault();
      targetElement.scrollIntoView({ behavior: 'smooth' });
      window.history.pushState({}, '', `#${targetId}`);
    }
  }
};
