import React, {FC} from 'react';
import {makeStyles, Theme} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  svg:{
    width: 22,
    height: 22,
    fill: 'none'
  },
  path: {
    fill: theme.palette.grey[600],
  },
}));

const Instagram: FC = () => {
  const classes = useStyles();

  return (
      <svg
          className={classes.svg}
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            className={classes.path}
            fillRule="evenodd"
            d="M16.87 3.808a1.32 1.32 0 1 0 0 2.64 1.32 1.32 0 0 0 0-2.64ZM11 14.666a3.667 3.667 0 1 1 0-7.333 3.667 3.667 0 0 1 0 7.333Zm0-9.315a5.65 5.65 0 1 0 0 11.299A5.65 5.65 0 0 0 11 5.35Zm8.954 10.093c-.057 1.252-.308 2.413-1.202 3.306-.894.895-2.055 1.146-3.307 1.202-1.16.053-1.508.065-4.445.065-2.938 0-3.285-.012-4.445-.065-1.252-.056-2.413-.307-3.307-1.202-.895-.893-1.146-2.054-1.202-3.306-.053-1.16-.065-1.51-.065-4.446 0-2.937.012-3.285.065-4.445.056-1.252.307-2.413 1.202-3.307.894-.894 2.055-1.145 3.307-1.202C7.715 1.992 8.062 1.98 11 1.98c2.937 0 3.285.012 4.445.064 1.252.057 2.413.308 3.307 1.202.894.894 1.145 2.055 1.202 3.307.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.286-.064 4.446Zm1.98-8.98c-.082-1.788-.52-3.358-1.78-4.619-1.26-1.26-2.83-1.698-4.619-1.779C14.362.012 13.987 0 11 0S7.638.012 6.465.066c-1.788.081-3.359.52-4.619 1.78C.586 3.105.147 4.675.066 6.462.013 7.637 0 8.013 0 11c0 2.988.013 3.363.066 4.536.081 1.788.52 3.358 1.78 4.62 1.26 1.258 2.831 1.697 4.62 1.778C7.637 21.987 8.012 22 11 22s3.362-.012 4.535-.066c1.788-.08 3.358-.52 4.62-1.779 1.26-1.26 1.697-2.83 1.78-4.619.052-1.173.065-1.548.065-4.536 0-2.987-.013-3.362-.066-4.536Z"
            clipRule="evenodd"
        />
      </svg>
  );
};

export default Instagram;