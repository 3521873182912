import React, { FC } from 'react';
import { Box, Button, Paper, Typography, Link } from '@material-ui/core';
import { Content, StructuredMetadataTable } from '@backstage/core-components';
import { UiSchema } from '@rjsf/utils';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import { JsonObject } from '@backstage/types';
import { ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import { isObject } from '@internal/plugin-eapi-common';
import Alert from '@material-ui/lab/Alert';

const isNotDisplayedValue = (value: unknown): boolean => {
  if (isObject(value) || Array.isArray(value)) {
    return isEmpty(value);
  } else if (typeof value === 'string') {
    return value.trim().length === 0;
  } else if (typeof value === 'number' || typeof value === 'boolean') {
    return false;
  } else return !value;
};

export function getReviewData(formData: Record<string, any>, uiSchemas: UiSchema[]) {
  return uiSchemas.reduce((reviewData, schema) => {
    const key = schema.name;

    if (!formData.hasOwnProperty(key)) return { ...reviewData };

    let displayedValue = formData[key];

    if (schema['ui:widget'] === 'password') {
      displayedValue = '******';
    }

    if (schema['format'] === 'url' && formData[key]) {
      const anchor = {
        color: 'blue',
        textDecoration: 'underline',
      };
      displayedValue = (
        <a target="_blank" href={formData[key]} style={anchor}>
          {formData[key]}
        </a>
      );
    }

    if (schema['ui:widget'] === 'image') {
      displayedValue = <img alt="service picture" src={formData[key]} height={100} />;
    }

    if (schema['ui:widget'] === 'file' && formData[key]) {
      displayedValue = (
        <Link download={key} href={formData[key]}>
          {schema.title}
        </Link>
      );
    }

    const render = schema['ui:review']?.render;
    if (render) {
      displayedValue = formData[key][render];
    }

    const review = schema['ui:backstage']?.review;
    if (review && review.mask) {
      displayedValue = review.mask;
    }

    if (review && review.show === false) {
      return { ...reviewData };
    }

    return isNotDisplayedValue(displayedValue)
      ? { ...reviewData }
      : {
          ...reviewData,
          [key]: displayedValue,
        };
  }, {});
}

const extractSchemaProperties = (schema: JsonObject, schemaProperties: JsonObject) => {
  if (!isObject(schema)) {
    return;
  }

  const { properties, dependencies, oneOf } = schema;

  if (isObject(properties)) {
    for (const propName of Object.keys(properties)) {
      schemaProperties[propName] = {
        ...(schemaProperties[propName] as JsonObject),
        ...(properties[propName] as JsonObject),
      };
    }
  }

  if (isObject(dependencies)) {
    for (const depName of Object.keys(dependencies)) {
      const schemaNode = dependencies[depName];
      if (!isObject(schemaNode)) {
        continue;
      }
      extractSchemaProperties(schemaNode, schemaProperties);
    }
  }

  if (Array.isArray(oneOf)) {
    for (const schemaNode of oneOf) {
      if (!isObject(schemaNode)) {
        continue;
      }
      extractSchemaProperties(schemaNode, schemaProperties);
    }
  }
};

const getSchemaProperties = (schema: JsonObject) => {
  const schemaProperties: JsonObject = {};
  extractSchemaProperties(schema, schemaProperties);

  return schemaProperties;
};

export function getUiSchemasFromSteps(
  steps: {
    schema: JsonObject;
    uiSchema: UiSchema;
  }[],
): UiSchema[] {
  const uiSchemas: Array<UiSchema> = [];

  steps.forEach((step) => {
    const schemaProps = getSchemaProperties(step.schema);

    for (const key in schemaProps) {
      if (!schemaProps.hasOwnProperty(key)) continue;

      const uiSchema = step.uiSchema?.hasOwnProperty(key)
        ? { ...(schemaProps[key] as JsonObject), ...step.uiSchema[key] }
        : schemaProps[key];
      uiSchema.name = key;
      uiSchemas.push(uiSchema);
    }
  });

  return uiSchemas;
}

export interface ReviewStepComponentProps extends ReviewStepProps {
  title?: string;
  finishButtonLabel?: string;
  isDisabledFinishButton?: boolean;
}

/**
 * The component displaying the Last Step in scaffolder template form.
 * Which represents the summary of the input provided by the end user.
 */
export const ReviewStepComponent: FC<ReviewStepComponentProps> = ({
  disableButtons,
  formData,
  handleBack,
  handleCreate,
  handleReset,
  steps,
  finishButtonLabel,
  isDisabledFinishButton = false,
  title = 'create',
}) => {
  const uiSchemas = getUiSchemasFromSteps(steps);

  /**
   * While we are using out-of-box functionality as a template with dependencies, we have to clean a formData here
   * to prevent saving data: if enableInTheDeveloperPortal was checked, data depended on it were populated,
   * and then it was unchecked - formData have to be deleted as well
   */
  if (!formData.enableInTheDeveloperPortal) {
    delete formData['tagLine'];
    delete formData['image'];
    delete formData['externalWebPageLink'];
  }

  const titleFormat = (name: string): string => uiSchemas.find((us) => us.name === name)?.title || startCase(name);

  return (
    <Content>
      <Paper square elevation={0}>
        <Typography variant="h6">{`Review and ${title}`}</Typography>
        <StructuredMetadataTable dense metadata={getReviewData(formData, uiSchemas)} options={{ titleFormat }} />
        {title && title === 'update' && (
          <Box py={3}>
            <Alert severity="info">
              <b>Please note:</b> Once you click UPDATE, the updates you've made will take a few minutes to propagate in the background.
              <p>In the meantime, whilst the updates are propagating, you will see the old values for your service being displayed.</p>
            </Alert>
          </Box>
        )}
        <Box mb={4} />
        <Button onClick={handleBack} disabled={disableButtons}>
          Back
        </Button>
        <Button onClick={handleReset} disabled={disableButtons}>
          Reset
        </Button>
        <Button variant="contained" color="primary" onClick={handleCreate} disabled={isDisabledFinishButton || disableButtons}>
          {finishButtonLabel ?? 'Create'}
        </Button>
      </Paper>
    </Content>
  );
};
