import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  secret: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
  },
  spacing: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.dark,
    },
  }
}));