import React, { FC } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { useAuth } from '../../hooks';

export const SignInButton: FC<ButtonProps> = ({ children, ...props }) => {
  const { signIn } = useAuth();
  const targetUrls = {
    internalUser: Routes.SOLUTIONS,
    externalUser: Routes.SUBSCRIPTIONS,
  };

  const handleSignIn = () => signIn(targetUrls);

  return (
    <Button {...props} onClick={handleSignIn}>
      {children}
    </Button>
  );
};
