const EMAIL_VALIDATION_REGEX = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';

export class EmailValidator {

    emailValidation(emailAddress: string) {
        const emailValidationRegex = new RegExp(EMAIL_VALIDATION_REGEX, 'gm');
        return emailValidationRegex.test(emailAddress);
    }

    isValidSwissReEmail(emailAddress: string): boolean {
        if(emailAddress) {
            const domain = emailAddress.split('@')[1];
            if(domain && domain.trim().toLowerCase() === 'swissre.com') {
                return this.emailValidation(emailAddress);
            } 
        }
        return false;
    }
}
