import { FieldValidation } from '@rjsf/utils';

export const uploadFileValidation = (
  value: string,
  validation: FieldValidation,
) => {
  if (value) {
    const valueArray = value.split(',');
    const fileTypeString = valueArray[0];
    const buffer = Buffer.from(valueArray[1]);
    /** a file, converted to base64 format, is bigger than actual one in 1.37 times.
     * so we have to apply a coefficient here to validate the actual file size */
    const COEFFICIENT = 1.37;
    const base64FileValueSize = buffer.length;
    const actualFileValueSize = base64FileValueSize / COEFFICIENT;

    if (fileTypeString.indexOf('image') !== -1) {
      actualFileValueSize > 512000 && validation.addError('The size of picture is too big');
    } else if (fileTypeString.indexOf('zip') !== -1) {
      actualFileValueSize > 2048000 && validation.addError('The size of zip file is too big');
    } else {
      validation.addError('Unsupported file type');
    }
  }
};
