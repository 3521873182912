import { Entity } from '@backstage/catalog-model';
import { SDSUserSubscriptionDetails } from './sds';

export enum AuthenticationType {
  SECRET = 'ClientCredentials',
  PUBLIC_KEY = 'ClientCredentialsWithAsymmetricSigning',
}

export interface Subscription {
  name: string;
  clientId: string;
  isActive: boolean;
}
export interface SubscriptionDetails {
  subscriptionDetails: SDSUserSubscriptionDetails;
  services?: Entity[];
}

export enum KeyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}
export interface SymmetricKey {
  id: string;
  status: KeyStatus;
  isLegacy: boolean;
  secret: string | null;
  createdAt: string;
  expireAt: string | null;
}

export interface PublicKey {
  name: string;
  key: string;
}

//TODO - Temporary solution, to fallback to old UI until all subscriptions gets migrated to ECSP
export interface SubscriptionSymmetricKey {
  oktaClientApplicationId?: string;
  clientSecret?: string;
}