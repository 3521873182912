import { Theme, makeStyles } from '@material-ui/core';
import { LinkButtonProps } from './LinkButton';

export const useStyles = makeStyles<Theme, Pick<LinkButtonProps, 'variant' | 'width'>>((theme) => ({
  link: (props) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '32px',
    backgroundColor: props.variant === 'primary' ? theme.palette.primary.dark : theme.palette.common.white,
    color: props.variant === 'primary' ? theme.palette.common.white : theme.palette.primary.dark,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    fontWeight: 400,
    width: props.width,
   
    '& svg': {
      marginLeft: theme.spacing(1),
    },

    '&:hover': {
      color: props.variant === 'primary' ? theme.palette.primary.dark : theme.palette.common.white,
      backgroundColor:  props.variant === 'primary' ? theme.palette.common.white : theme.palette.primary.dark,
    },
  }),
}));
