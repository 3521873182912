import { SDSEnvironmentType, Solution } from "@internal/plugin-eapi-common";
import { formatDate } from "@internal/plugin-eapi-react";


export const CONSUMPTION_NOTE = "Contractual consumption information is refreshed on a daily basis, and may be up to 48 hours out of date. Data last refreshed:";
export const TRIAL_NPD_NOTE = "Usage for Trial and Non-Production Subscriptions is monitored but may not be billed, as per contract agreement.";

export const getAlertContent = (environmentType: SDSEnvironmentType, isTrial?: boolean, selectedSolution?: Solution,  ) => {
  const isProd = environmentType === SDSEnvironmentType.PROD;
  const title = isProd && !isTrial ? "Note" : "Notes"
  const defaultMessage = selectedSolution ? [`${CONSUMPTION_NOTE} ${formatDate(selectedSolution.dateTime, { dateStyle: 'long'},)}`] : []
  const message = isProd && !isTrial ? defaultMessage : [...defaultMessage, TRIAL_NPD_NOTE]
  
  return {title, message}
}