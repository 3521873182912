import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  overlaidContent: {
    position: 'relative',
    zIndex: 2,
    color: theme.palette.common.white,
    height: '100%',
    padding: '0 2rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem'
    }
  }
}));
