import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  background:  {
    position: 'relative',
    width: '100%',
    height: 560,
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5))',
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: '100%',
    objectFit: "cover",
    objectPosition: "center",
    zIndex: 1,
  }
});
