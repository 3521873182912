import React, { FC } from 'react';
import { useStyles } from './styles';

export const SwaggerIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg
      data-testid="swagger-icon"
      className={classes.svg}
      width={19}
      height={19}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          className={classes.secondaryPath}
          d="M9.43 19C4.368 18.965-.034 14.764 0 9.426.033 4.29 4.301-.034 9.582 0 14.715.034 19.045 4.295 19 9.514c.042 5.173-4.31 9.522-9.57 9.486Zm0 0C4.368 18.965-.034 14.764 0 9.426.033 4.29 4.301-.034 9.582 0 14.715.034 19.045 4.295 19 9.514c.042 5.173-4.31 9.522-9.57 9.486Z"
        />
        <path
          className={classes.primaryPath}
          d="M9.44 17.738c-4.39-.03-8.208-3.674-8.178-8.302.028-4.455 3.73-8.205 8.31-8.174 4.45.03 8.206 3.724 8.166 8.25.037 4.486-3.737 8.258-8.299 8.226Zm0 0c-4.39-.03-8.208-3.674-8.178-8.302.028-4.455 3.73-8.205 8.31-8.174 4.45.03 8.206 3.724 8.166 8.25.037 4.486-3.737 8.258-8.299 8.226Z"
        />
        <path
          className={classes.secondaryPath}
          d="M12.567 9.497c-.02.392-.364.718-.702.662h-.005a.678.678 0 0 1-.679-.678.688.688 0 0 1 .7-.665c.374.002.707.319.686.68Zm-6.02 3.805c.14.004.282 0 .444 0v1.024c-1.011.17-1.845-.116-2.05-.972a5.677 5.677 0 0 1-.128-.956c-.022-.341.015-.686-.01-1.026-.073-.936-.194-1.255-1.092-1.3V8.907c.064-.015.13-.026.195-.033.493-.024.7-.175.81-.66.05-.273.08-.549.09-.826.038-.536.024-1.08.114-1.607.129-.762.602-1.132 1.383-1.174.222-.012.445-.001.697-.001V5.65c-.103.008-.198.023-.293.02-.637-.02-.67.197-.716.724-.029.331.011.667-.011.999-.024.33-.068.658-.133.983-.092.47-.38.82-.782 1.117.778.507.867 1.293.918 2.092.027.43.014.862.058 1.29.034.33.163.415.505.426Zm.655-4.486h.012a.674.674 0 0 1 .661.722.66.66 0 0 1-.696.62h-.044a.671.671 0 0 1 .067-1.342Zm2.318 0c.407-.003.677.26.68.663.002.413-.254.678-.659.68-.41.001-.681-.259-.684-.659a.644.644 0 0 1 .663-.684Zm4.854-.51c.108.405.32.547.745.567.07.003.139.015.235.025v1.165a.993.993 0 0 1-.16.038c-.57.035-.83.27-.887.841-.037.365-.034.734-.059 1.1-.01.403-.047.804-.11 1.201-.145.72-.595 1.08-1.338 1.124-.239.014-.48.002-.738.002v-1.04c.14-.01.262-.021.385-.024.444-.01.6-.154.622-.595.024-.484.035-.969.056-1.453.032-.7.223-1.326.876-1.763-.373-.266-.673-.588-.75-1.023-.095-.526-.125-1.065-.175-1.599-.025-.267-.024-.536-.05-.803-.028-.288-.226-.388-.489-.394-.15-.004-.3-.001-.492-.001V4.658c1.225-.204 2.071.204 2.15 1.376.033.492.028.987.06 1.48a4.1 4.1 0 0 0 .12.793Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path className={classes.secondaryPath} d="M0 0h19v19H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
