import { useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

export const useTabHistorySync = (tabValue: string, tabSetter: Dispatch<SetStateAction<string>>) => {
  const { tabName } = useParams();

  useEffect(() => {
    // Listening browser go back/go forward buttons and setting the tab value

    if (!tabName || tabName === tabValue) return;

    tabSetter(tabName);
  }, [tabName, tabValue, tabSetter]);
};
