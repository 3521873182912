import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { PanelComponentProps } from '../../constants';
import { TechServicesCards } from '../TechServicesCards';
import { useStyles } from './styles';

export const OverviewTab: FC<PanelComponentProps> = ({ entity }) => {
  const classes = useStyles();

  if(!entity) return null;

  return (
    <Box mb={8}>
      <Typography gutterBottom className={classes.description}>{entity?.metadata.description}</Typography>
      <TechServicesCards entity={entity} />
    </Box>
  );
};
