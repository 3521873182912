import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';
import { Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Table } from '@backstage/core-components';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { ImageMessage } from '../../../../components/ImageMessage';
import { useApiAnalyticsApi } from '../../../../hooks';
import { columns, options } from './columns';
import { TimePeriodFilter, TimePeriodOption } from '../TimePeriodFilter';

const DEFAULT_DATE_FROM_FILTER: TimeUnitRangeValue = 'now-1d';

export const OPTIONS: TimePeriodOption<TimeUnitRangeValue>[] = [
  { name: 'Last 24 hours', value: 'now-1d' },
  { name: 'Last 7 days', value: 'now-7d' },
  { name: 'Last 15 days', value: 'now-15d' },
];

export const ApiActivity: FC = () => {
  const apiAnalyticsApi = useApiAnalyticsApi();
  const [timePeriodFilter, setTimePeriodFilter] = useState<TimeUnitRangeValue>(DEFAULT_DATE_FROM_FILTER);
  const { clientId } = useParams();

  const {
    value: apiStats,
    loading,
    error,
  } = useAsync(async () => {
    if (!clientId) return;

    return await apiAnalyticsApi.getApiStats(clientId, timePeriodFilter);
  }, [clientId, timePeriodFilter]);

  const title = (
    <>
      <Typography variant="h5" gutterBottom component="p">
        API Calls Breakdown
      </Typography>
      <Typography variant="body2">
        <b>{apiStats?.totalHits}</b> Total calls for the selected period
      </Typography>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TimePeriodFilter defaultValue={DEFAULT_DATE_FROM_FILTER} setFilter={setTimePeriodFilter} options={OPTIONS} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        {loading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        {!loading && apiStats?.apiHitActivity &&  !!apiStats?.apiHitActivity?.length && (
          <Table
            data={apiStats.apiHitActivity}
            title={title}
            isLoading={loading}
            columns={columns}
            options={options}
            totalCount={apiStats.apiHitActivity.length}
          />
        )}
        {!loading && (error || apiStats?.apiHitActivity?.length === 0) && (
          <ImageMessage
            type={error ? 'error' : 'info'}
            title={error ? 'Failed to load API activity information.' : 'No API activity information available yet.'}
          />
        )}
      </Grid>
    </Grid>
  );
};
