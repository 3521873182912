import React, { FC } from 'react';
import { MembersForm } from './components/MembersForm';
import { MembersList } from './components/MembersList';

export const MembersTab: FC = () => {
  return (
    <>
      <MembersForm />
      <MembersList />
    </>
  );
};
