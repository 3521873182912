import React, { FC } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import { SDSEnvironmentType } from '@internal/plugin-eapi-common';

interface SubscriptionChipProps {
  environmentType: SDSEnvironmentType;
  subscriptionName: string;
  isTrial?: boolean;
}

export const SubscriptionChip: FC<SubscriptionChipProps> = ({ subscriptionName, environmentType, isTrial }) => {
  const isNonProd = environmentType === SDSEnvironmentType.NON_PROD || environmentType === SDSEnvironmentType.DEMO;
  
  return (
    <Box display="flex" gridColumnGap={20} alignItems="center" my={4}>
      <Typography variant="h3">{subscriptionName}</Typography>
      {isNonProd && <Chip label="Non-Prod" variant="outlined" role="contentinfo" />}
      {isTrial && <Chip label="Trial" variant="outlined" color="secondary" role="contentinfo" />}
    </Box>
  );
};
