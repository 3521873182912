import React, { useCallback, useState } from 'react';

import { useApi, configApiRef } from '@backstage/core-plugin-api';

import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { BrowserType, getBrowserType } from '@internal/plugin-eapi-common';

import { aadApiRef } from '../api';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export interface EmailFieldInterface {
  text?: string;
  validity?: boolean;
  errorMessage?: string;
}

export const EmailValidatorExtension = (props: FieldExtensionComponentProps<EmailFieldInterface>) => {
  const {
    onChange,
    required,
    schema,
    formData,
    name
  } = props;

  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const AADApi = useApi(aadApiRef);
  const configApi = useApi(configApiRef);

  const handleBlur = function () {
    setErrorMessage('');
  };

  const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {

    const { target: { value } } = event;
    setValid(false);
    const fieldProperties: EmailFieldInterface = {
      text: value,
      validity: false
    }
    if (value.length !== 0) {
      setLoading(true);
      setErrorMessage('The email validation is taking longer then expected, please wait a couple of seconds more');
      const response = await AADApi.validateEmail(value).then(res => res);
      setLoading(false);
      setErrorMessage('');
      if (response?.status === 200 && response?.isValidEmail) {
        setValidityAndErrorMessage(true, "");
      } else {
        setValidityAndErrorMessage(false, response?.errorMessage ?? "Email address does not exist in our system")
      }
    }
    onChange(fieldProperties);

    function setValidityAndErrorMessage(valid: boolean, errorMessage: string) {
      setValid(valid);
      setInvalid(!valid);
      fieldProperties.validity = valid;
      fieldProperties.errorMessage = errorMessage;
      setErrorMessage(errorMessage);
    }
  }, [configApi]);


  return (
    <TextField
      label={schema.title}
      id={`${name}-input`}
      autoComplete={getBrowserType() === BrowserType.Edge ? 'new-password' : 'on'}
      required={required}
      error={invalid}
      value={formData?.text ?? ''}
      helperText={errorMessage ?? null}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {errorMessage ? <ErrorIcon color="error" /> : null}
            {valid ? <CheckIcon color="inherit" /> : null}
          </React.Fragment>
        )
      }}
    />
  );
};