import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import fallbackImageAPIPlatform from '../../../../../../assets/home/fallback-image-api-platform.jpg';
import { useStyles } from './styles';

export interface VideoBackgroundProps {
  videoSourceName: string;
}

export const VideoBackground: FC<PropsWithChildren<VideoBackgroundProps>> = ({ videoSourceName, children }) => {
  const classes = useStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoError, setIsVideoError] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play()?.catch(() => {
        setIsVideoError(true);
      });
    }
  }, []);

  return (
    <Box className={classes.background}>
      {isVideoError ? (
        <img src={fallbackImageAPIPlatform} alt="Fallback image for Swiss Re Developer Portal background" className={classes.media} loading='lazy'/>
      ) : (
        <video ref={videoRef} autoPlay loop muted className={classes.media}>
          <source src={`/${videoSourceName}`} type="video/mp4" />
        </video>
      )}
      {children}
    </Box>
  );
};
