import React, { FC, PropsWithChildren } from "react";
import { useAuth } from "../../hooks";

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return null
  }

  return <>{children}</>
};