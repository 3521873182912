import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  ReactElement,
} from 'react';
import { useParams } from 'react-router';
import { useAsyncRetry } from 'react-use';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useEncryptionApi } from '../../../../../../../../hooks';
import { UploadKeyModal } from './components/UploadKeyModal';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useStyles } from './styles';


export const AsymmetricKeys: FC = () => {
  const classes = useStyles();
  const encryptionApi = useEncryptionApi();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationType, setNotificationType] = useState<'error' | 'success'>(
    'success',
  );
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const { clientId } = useParams();

  if (!clientId) return;

  const { value, loading, error, retry } = useAsyncRetry(async () => await encryptionApi.getPublicKeys(clientId), [clientId]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const handleUploadKey = useCallback(
    (type: 'success' | 'error', message: string) => {
      setNotificationOpen(true);
      setNotificationType(type);
      setNotificationMessage(message);

      if (type === 'success' && !loading) {
        retry();
      }
    },
    [loading, retry],
  );

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
  };

  useEffect(() => {
    if (!error) return;

    setNotificationOpen(true);
    setNotificationType('error');
    setNotificationMessage(error.message);
  }, [error]);

  const publicKey: ReactElement = useMemo(() => {
    if (!value) return <Typography>Not available</Typography>;

    const { key } = value;

    return key ? (
      <code className={classes.key}>{key}</code>
    ) : (
      <Typography>No public keys set</Typography>
    );
  }, [value]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Public key
      </Typography>
      <Box mb={2} py={1}>
        {loading ? (
          <CircularProgress data-testid="circular-progress" size={20} />
        ) : (
          publicKey
        )}
      </Box>
      <Button
        color="default"
        startIcon={<CloudUploadIcon />}
        onClick={handleOpenModal}
      >
        Upload new Public Key
      </Button>
      <UploadKeyModal
        clientId={clientId}
        open={isModalOpen}
        onModalClose={handleCloseModal}
        onUploadKey={handleUploadKey}
      />
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </>
  );
};
