import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderLeft:"5px solid #3C4150",
    backgroundColor: theme.palette.grey[50],
  },
  listHeading: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
}));
