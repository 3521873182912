import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useAsync } from 'react-use';
import { FeatureFlagNames } from '@internal/plugin-eapi-common';
import { useDevPortalApi } from '../../api';

const FeatureFlagsContext = createContext<Record<FeatureFlagNames, boolean> | undefined>(undefined);

export const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const devPortalApi = useDevPortalApi();

  const { value, error } = useAsync(async () => {
    const featureFlagsConfig = await devPortalApi.getFeatureFlags();

    return Object.fromEntries(Object.entries(featureFlagsConfig).map(([key, value]) => [key, JSON.parse(value)])) as Record<
      FeatureFlagNames,
      boolean
    >;
  }, []);

  if (error) {
    console.error('Failed to load feature flags.', error.message);
  }

  return <FeatureFlagsContext.Provider value={value} children={children} />;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);