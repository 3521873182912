export const EXCEPTION_LIST = [
    'refresh',
    'logout'
];

export enum SesionStatusType {
    EXPIRED = 'expired',
    ACTIVE = 'active',
    REFRESH = 'refresh'
}

export enum LogOutModeType {
    MANUAL = 'manual',
    AUTO = 'auto'
}