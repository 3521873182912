import React, { FC } from 'react';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { useStyles } from './styles';

interface PieCenterLabelProps {
  labelValue: number;
}

export const PieCenterLabel: FC<PieCenterLabelProps> = ({ labelValue }) => {
  const classes = useStyles();

  const { width, height, left, top } = useDrawingArea();
  return (
    <text className={classes.label} x={left + width / 2} y={top + height / 2}>
      {labelValue.toFixed(2) + '%' + '\n' + 'used'}
    </text>
  );
};
