import { discoveryApiRef, useApi } from "@backstage/core-plugin-api";
import { useCoreApi } from "../useCoreApi";
import { ApiStats, EAPIError, Solution } from "@internal/plugin-eapi-common";

export const useApiAnalyticsApi = () => {
    const { doGet } = useCoreApi();
    const discoveryApi = useApi(discoveryApiRef);

    const getApiStats = async (clientId: string, dateFrom: string): Promise<ApiStats> => {
        try {
            const url = await discoveryApi.getBaseUrl(`api-analytics/${clientId}/activity?dateFrom=${dateFrom}`);
            const response = (await doGet(url)) as any;
            return response.data;
        } catch (error: any) {
            console.error('get LaaS Api stats call error :: ' + error.message);
            const eapiError = {
                status: error.status,
                name: error.name,
                message: error.message,
                description: error.description,
            };
            throw new EAPIError(eapiError);
        }
    }
    
    const getApiConsumption = async (clientId: string, dateFrom: string): Promise<Solution[]> => {
        try {
            const url = await discoveryApi.getBaseUrl(`api-analytics/${clientId}/consumption?dateFrom=${dateFrom}`);
            const response = (await doGet(url)) as any;
            
            return response.data;
        } catch (error: any) {
            console.error('get LaaS Api consumption call error :: ' + error.message);
            const eapiError = {
                status: error.status,
                name: error.name,
                message: error.message,
                description: error.description,
            };
            throw new EAPIError(eapiError);
        }
    }

    return { getApiStats, getApiConsumption }
}