import React, { FC, useEffect } from 'react';
import { useAsync } from 'react-use';
import { ContainerName } from '@internal/plugin-eapi-common';
import { Card } from '../../../../components/Card';
import placeholder from '../../../../assets/placeholder-bs.jpg';
import { useEAPIAssetsApi } from '../../../../hooks';
import { Typography, TypographyProps } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export interface SolutionCardProps {
  title: string;
  description: string;
  href: string;
  altText: string;
  name: string;
  imageName?: string;
};

export const SolutionCard: FC<SolutionCardProps> = ({ title, description, href, name, imageName, altText }) => {
  const eapiAssetsApi = useEAPIAssetsApi();
  const variants = ['h1', 'h3', 'body1', 'caption'] as TypographyProps['variant'][];
  const { value: image, loading } = useAsync(async () => {
    if (!imageName) return;
    const blobImage = await eapiAssetsApi.fetchFile<Blob>(ContainerName.AUTHORED, `business-services/${name}/${imageName}`);

    return URL.createObjectURL(blobImage);
  }, [imageName]);

  useEffect(() => () => {
    if (image) URL.revokeObjectURL(image);
  }, [image]);

  if (loading)
    return (
      <div>
        {variants.map((variant) => (
          <Typography component="div" key={variant} variant={variant}>
            <Skeleton />
          </Typography>
        ))}
      </div>
    );

  return <Card image={image || placeholder} title={title} description={description} href={href} altText={altText} />;
};
