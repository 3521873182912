import React, { useState, MouseEvent } from 'react';
import { Box, IconButton, Link, Popover } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Links } from '../../../../constants/links';
import { useStyles } from './styles';

export const SupportMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'support-popover' : undefined;

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick} color="inherit">
        <HelpIcon fontSize="small" className={classes.icon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <HelpIcon fontSize="small" className={classes.icon} />
            <Link
              target="_blank"
              rel="noopener"
              href={Links.SERVICE_NOW}
              className={classes.link}
            >
              Support Portal
            </Link>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
