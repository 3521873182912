import React, { FC } from 'react';
import { useStyles } from './styles';

export const InfoIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg data-testid="info-icon" className={`${classes.svg} ${classes.cursor}`} width={18} height={18} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.16669 13.167H9.83335V8.16699H8.16669V13.167ZM9.00002 6.50033C9.23613 6.50033 9.43405 6.42046 9.59377 6.26074C9.75349 6.10102 9.83335 5.9031 9.83335 5.66699C9.83335 5.43088 9.75349 5.23296 9.59377 5.07324C9.43405 4.91352 9.23613 4.83366 9.00002 4.83366C8.76391 4.83366 8.56599 4.91352 8.40627 5.07324C8.24655 5.23296 8.16669 5.43088 8.16669 5.66699C8.16669 5.9031 8.24655 6.10102 8.40627 6.26074C8.56599 6.42046 8.76391 6.50033 9.00002 6.50033ZM9.00002 17.3337C7.84724 17.3337 6.76391 17.1149 5.75002 16.6774C4.73613 16.2399 3.85419 15.6462 3.10419 14.8962C2.35419 14.1462 1.76044 13.2642 1.32294 12.2503C0.885437 11.2364 0.666687 10.1531 0.666687 9.00033C0.666687 7.84755 0.885437 6.76421 1.32294 5.75033C1.76044 4.73644 2.35419 3.85449 3.10419 3.10449C3.85419 2.35449 4.73613 1.76074 5.75002 1.32324C6.76391 0.885742 7.84724 0.666992 9.00002 0.666992C10.1528 0.666992 11.2361 0.885742 12.25 1.32324C13.2639 1.76074 14.1459 2.35449 14.8959 3.10449C15.6459 3.85449 16.2396 4.73644 16.6771 5.75033C17.1146 6.76421 17.3334 7.84755 17.3334 9.00033C17.3334 10.1531 17.1146 11.2364 16.6771 12.2503C16.2396 13.2642 15.6459 14.1462 14.8959 14.8962C14.1459 15.6462 13.2639 16.2399 12.25 16.6774C11.2361 17.1149 10.1528 17.3337 9.00002 17.3337Z"
        fill="#00AA91"
      />
    </svg>
  );
};