export enum FieldLabels {
    BUSINESS_EMAIL = 'Business email',
}

export enum FieldNames {
    BUSINESS_EMAIL = 'businessEmail',
}

export interface MembersFormData {
    [FieldNames.BUSINESS_EMAIL]: string;
}