import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Box, Typography, LinearProgress, Link, Tabs, Tab } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { EntityLink } from '@backstage/catalog-model';
import { Page } from '../../layout/Page';
import { useCatalogApi, useTabHistorySync } from '../../hooks';
import { AudienceType, getEntityTitle } from '@internal/plugin-eapi-common';
import { TechServicesCards } from './components/TechServicesCards';
import { TabPanel } from '../../components/TabPanel';
import { TAB_PATHS, TABS_CONFIGURATION as tabConfig } from './constants';
import { useStyles } from './styles';
import { Routes } from '../../constants/routes';

export const SolutionDetails = () => {
  const classes = useStyles();
  const { solutionName, tabName } = useParams();
  const [tabValue, setTabValue] = useState(tabName || TAB_PATHS.OverviewTab);
  useTabHistorySync(tabValue, setTabValue);
  const { getEntities } = useCatalogApi();
  const AUDIENCE_KEY = 'swissre.com/audience';

  const {
    value: entity,
    loading,
    error,
  } = useAsync(async () => {
    const appliedFilter = {
      kind: 'system',
      [`metadata.annotations.${AUDIENCE_KEY}`]: AudienceType.EXTERNAL,
      ['metadata.name']: solutionName || '',
    };

    const { items } = await getEntities(appliedFilter);

    return items[0];
  }, []);

  const handleTabChange = (_event: ChangeEvent<{}>, value: any) => setTabValue(value);

  const entityLink: EntityLink | undefined = entity?.metadata?.links?.find((link: EntityLink) => link.type === 'externalWebLink');
  const externalWebPageLink = entityLink?.url;
  const annotations = entity?.metadata?.annotations;

  const hasTechDocs = !!annotations?.['backstage.io/techdocs-ref'];

  if (loading) {
    return (
      <Page>
        <LinearProgress />
      </Page>
    );
  }

  if (error) {
    return (
      <Page>
        <Alert severity="error">
          <AlertTitle>Could not load a solution details</AlertTitle>
          {error.message}
        </Alert>
      </Page>
    );
  }

  if (!entity) return null;

  return (
    <>
      <Page
        title={getEntityTitle(entity)}
        subtitle={entity?.metadata?.annotations?.['swissre.com/tag-line']}
        description={hasTechDocs ? undefined : entity?.metadata.description}
        externalWebPageLink={externalWebPageLink}
      >
        {hasTechDocs ? (
          <Box mb={8}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tabValue}
              onChange={handleTabChange}
              aria-label="solution details page tabs"
            >
              {tabConfig.map(({ label, path }, index) => (
                <Tab
                  to={`${Routes.SOLUTIONS}/${solutionName}/${path}`}
                  component={RouterLink}
                  id={`nav-tab-${index}`}
                  label={label}
                  value={path}
                  key={path}
                  aria-controls={`nav-tabpanel-${index}`}
                />
              ))}
            </Tabs>
            {tabConfig.map(({ panelComponent: PanelComponent, path }, index) => (
              <TabPanel value={path} index={index} key={path}>
                  <PanelComponent entity={path === TAB_PATHS.OverviewTab ? entity : undefined} />
              </TabPanel>
            ))}
          </Box>
        ) : (
          <Box mb={8}>{entity && <TechServicesCards entity={entity} />}</Box>
        )}
      </Page>
      <Box className={classes.contacts} py={10} px={2}>
        <Typography gutterBottom variant="h3">
          Contact us to get access to Swiss Re APIs
        </Typography>
        <Typography>Our team will help you setting up a subscription.</Typography>
        <Typography>
          Email us at&nbsp;
          <Link
            underline="always"
            variant="inherit"
            className={classes.devPortalLink}
            href={`mailto:developer_portal@swissre.com?subject=${getEntityTitle(entity)} query`}
          >
            developer_portal@swissre.com
          </Link>
        </Typography>
      </Box>
    </>
  );
};
