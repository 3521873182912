import { makeStyles, Theme } from '@material-ui/core';
import { PageProps } from './Page';

export const useStyles = makeStyles<Theme, Partial<PageProps>>(theme => ({
  title: {
    marginBottom: ({ subtitle, description }) =>
      !!subtitle || !!description ? theme.spacing(1) : 0,
  },
  subtitle: {
    marginBottom: ({ description }) => (!!description ? theme.spacing(3) : 0),
  },
  description: {
    whiteSpace: 'pre-line',
  }
}));
