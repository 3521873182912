export enum ContainerName {
  SHARED = 'shared',
  AUTHORED = 'authored',
  EAPI_TECH_DOCS = 'eapi-techdocs'
}

export enum FileType {
  Html = 'html',
  Img = 'img',
  Json = 'json',
  Zip = 'zip',
  Pdf = 'pdf',
}