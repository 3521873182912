/* types for config file */
export enum FeatureFlagNames {
  // Add here new feature flag name
};

export enum BooleanConfigType {
  ENABLED = "true",
  DISABLED = "false"
}

export type FeatureFlagsConfigType = Record<FeatureFlagNames, BooleanConfigType>;