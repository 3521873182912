import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    height: '40px',
    minWidth: '180px',
    border: 'none',
    borderRadius: '25px',
    color: theme.palette.text.primary,

    '&.Mui-selected': {
      borderRadius: '25px',
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },

    '&:hover': {
      borderRadius: '25px',
    },
  },
  buttonGroup: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '40px',
  }
}));