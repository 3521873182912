import React, { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export interface SectionProps {
  title: string;
  isBottomBorder?: boolean;
}

export const Section: FC<PropsWithChildren<SectionProps>> = ({ title, children, isBottomBorder = true }) => {
  const classes = useStyles({ isBottomBorder });

  return (
    <Box py={6} className={classes.wrapper}>
      <Typography className={classes.heading} variant="h2" align="center">
        {title}
      </Typography>
      {children}
    </Box>
  );
};
