import React, { ChangeEvent, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FormControl, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { getEntityTitle } from '@internal/plugin-eapi-common';
import isEmpty from 'lodash/isEmpty';
/*
 This is the actual component that will get rendered in the form
*/

export interface AutoCompleteFieldInterface {
  name?: string;
  label?: string;
}

export const EntityAutocompleteExtension = (props: FieldExtensionComponentProps<AutoCompleteFieldInterface>) => {
  const { onChange,
    rawErrors,
    required,
    formData,
    uiSchema,
    schema
  } = props;

  const handleChange = (
    _: ChangeEvent<{}>,
    newValue: any,
  ) => {
    onChange(newValue)
    newValue != null ? setValue(newValue) : setValue({});
  };

  const catalogApi = useApi(catalogApiRef);
  const [services, setServices] = useState<any>();
  const [value, setValue] = useState<string | string[] | null | {}>();

  useEffect(() => {
    const entityKind = uiSchema['ui:options']?.entityKind as string;
    async function getServices() {
      const response = await catalogApi.getEntities({
        filter: { kind: entityKind },
      });
      const servicesList: Array<{ label: string | undefined, name: string }> = response.items.map(item => {
        return { label: getEntityTitle(item), name: item.metadata.name }
      }).sort((a, b) => a.label?.localeCompare(b.label));
      const formDataObject = formData && servicesList.find(item => {
        return item.name === formData.name;
      });
      if (!isEmpty(formDataObject)) {
        onChange(formDataObject === null ? {} : formDataObject)
      }
      setValue(formDataObject);
      setServices(servicesList);
    }
    getServices();
  }, []);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        options={services || []}
        getOptionLabel={(option: any) => option.label || ''}
        value={value || ''}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) =>
          <TextField {...params} variant="standard" label={schema.title} helperText={schema.description} />}
        onChange={handleChange}
        closeIcon={null}
      />
    </FormControl>
  );
};
