import React, { FC, PropsWithChildren } from "react";
import { useMatch } from "react-router";
import { Routes } from "../../../constants/routes";
import { PersistLogin } from "../../../components/PersistLogin";
import { Box } from "@material-ui/core";

export const PageLayoutWrapper: FC<PropsWithChildren> = ({ children }) => {
    const isSignedOutRoute = useMatch(Routes.SIGNED_OUT);

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            {isSignedOutRoute ? <>{children}</> : <PersistLogin>{children}</PersistLogin>}
        </Box>
    )
}
