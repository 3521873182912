import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { UploadFileExtension } from './UploadFileExtension';
import { uploadFileValidation } from "./validation";

export const UploadFileFieldExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'UploadFileExtension',
      component: UploadFileExtension,
      validation: uploadFileValidation
    }),
);

