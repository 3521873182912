import React, { FC, useCallback, memo, useState } from 'react';
import { useParams } from 'react-router';
import { useAsyncRetry } from 'react-use';
import { AxiosError } from 'axios';
import { Typography, Box, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SymmetricKey } from '@internal/plugin-eapi-common';
import { formatDateToUTC } from '@internal/plugin-eapi-react';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useEncryptionApi } from '../../../../../../../../hooks';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { ColoredText } from '../../../../../../../../components/ColoredText';
import { KeysListTable } from './components/KeysListTable';
import { GenerateKeyModal } from './components/GenerateKeyModal';

enum ModalType {
  Delete = 'delete',
  Generate = 'generate',
}
interface Notification {
  type: 'error' | 'success';
  message: string;
  open: boolean;
}

export const SymmetricKeys: FC = memo(() => {
  const { clientId } = useParams();
  const encryptionApi = useEncryptionApi();

  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const [notification, setNotification] = useState<Notification | null>(null);
  const [keyIdToDelete, setKeyIdToDelete] = useState<string>('');
  const [generationLoading, setGenerationLoading] = useState<boolean>(false);
  const [generatedKeyData, setGeneratedKeyData] = useState<SymmetricKey | null>(null);

  if (!clientId) return;

  const {
    value: keyList,
    loading,
    error,
    retry,
  } = useAsyncRetry(async () => {
    return await encryptionApi.getSecrets(clientId);
  }, [clientId]);

  const handleNotificationClose = () => {
    setNotification({ type: 'success', message: '', open: false });
  };

  const handleDeleteKeyModalOpen = useCallback(
    (keyId: string) => () => {
      setOpenModal(ModalType.Delete);
      setKeyIdToDelete(keyId);
    },
    [],
  );

  const handleDeleteKeyModalClose = useCallback(() => {
    setOpenModal(null);
    setKeyIdToDelete('');
  }, []);

  const handleGenerateKeyModalOpen = useCallback(() => {
    setOpenModal(ModalType.Generate);
    setGeneratedKeyData(null);
    handleKeyGenerate();
  }, []);

  const handleGenerateKeyModaClose = useCallback(async () => {
    setOpenModal(null);
    retry();
  }, [retry, setOpenModal]);

  const handleError = (error: unknown) => {
    const errorMessage =
      error instanceof AxiosError
        ? error.response?.data?.message
        : 'A problem has occurred, please try again or contact the Developer Portal team if the error persists.';
    setNotification({ type: 'error', message: errorMessage, open: true });
  };

  const handleSuccess = (message: string) => {
    setNotification({ type: 'success', message: message, open: true });
  };

  const handleKeyDelete = useCallback(async () => {
    try {
      await encryptionApi.deleteSecret(keyIdToDelete, clientId);
      handleSuccess('The key has been deleted successfully');
      await retry();
    } catch (error) {
      handleError(error);
    } finally {
      handleDeleteKeyModalClose();
    }
  }, [clientId, handleDeleteKeyModalClose, keyIdToDelete, encryptionApi]);

  const handleKeyGenerate = useCallback(async () => {
    try {
      setGenerationLoading(true);
      const data = await encryptionApi.addSecret(clientId);
      setGeneratedKeyData(data);
      handleSuccess('The key was generated successfully');
    } catch (error) {
      handleError(error);
      setOpenModal(null);
    } finally {
      setGenerationLoading(false);
    }
  }, [clientId, encryptionApi]);

  const isMaxKeysAmount = keyList && keyList.length >= 2;
  const creationDate = keyList?.find((key) => key.id === keyIdToDelete)?.createdAt;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Client Secret
      </Typography>
      <KeysListTable keysData={keyList} onKeyDelete={handleDeleteKeyModalOpen} isLoading={loading} error={error} />
      <Box mt={3}>
        <Tooltip title={!loading && isMaxKeysAmount ? 'You can have a maximum of two Client Secrets simultaneously.' : ''} placement="top">
          <Box display="inline">
            <Button
              role="button"
              disabled={loading || isMaxKeysAmount}
              variant="outlined"
              onClick={handleGenerateKeyModalOpen}
              startIcon={<AddIcon />}
            >
              Generate new Client Secret
            </Button>
          </Box>
        </Tooltip>
      </Box>
      {notification && (
        <NotificationSnackBar
          open={notification.open}
          onClose={handleNotificationClose}
          message={notification.message}
          type={notification.type}
        />
      )}
      <ConfirmationModal
        open={openModal === ModalType.Delete}
        onClose={() => setOpenModal(null)}
        onConfirm={handleKeyDelete}
        title="Delete Client Secret"
        question="Are you sure you want to delete the following Client Secret?"
        note="The Client Secret cannot be recovered after deletion."
        confirmText="Delete Client Secret"
      >
        <Box display="flex" justifyContent="center" alignItems="baseline" mt={6} mb={4} gridColumnGap={8}>
          <Typography variant="h5">ID</Typography>
          <ColoredText variant='body2'>{keyIdToDelete}</ColoredText>
          <Typography variant="h5">Creation Date</Typography>
          <ColoredText variant='body2'>{creationDate ? formatDateToUTC(creationDate) : 'n/a'}</ColoredText>
        </Box>
      </ConfirmationModal>
      <GenerateKeyModal
        open={openModal === ModalType.Generate}
        onConfirm={handleGenerateKeyModaClose}
        loading={generationLoading}
        secret={generatedKeyData?.secret ?? null}
      />
    </>
  );
});

SymmetricKeys.displayName = 'SymmetricKeys';
