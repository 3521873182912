import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  hintText: {
    fontSize: 16,
    fontStyle: 'italic',
  },
  hintTextLink: {
    display: 'inline-block',
  },
});
