import React, { FC, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, Box, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { SDSUser } from '@internal/plugin-eapi-common';
import { useAuth, useSubscriptionApi } from '../../../../../../../../hooks';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useSubscription } from '../../../../../../../../hooks';
import { ColoredText } from '../../../../../../../../components/ColoredText';

const useStyles = makeStyles(() => ({
  tableCellHead: {
    border: 'none',
  },
  tableCellBody: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
  },
}));

export const MembersList: FC = () => {
  const classes = useStyles();
  const { subscription, setSubscription } = useSubscription();
  const { user } = useAuth();
  const loggedUserEmail = user?.profile?.email;
  const subscriptionsApi = useSubscriptionApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removedMemberEmail, setRemovedMemberEmail] = useState<string>('');
  const [notificationType, setNotificationType] = useState<'error' | 'success'>('success');
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  if (!subscription) return null;

  const {
    subscriptionDetails: { clientId, users },
  } = subscription;

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setRemovedMemberEmail('');
  }, []);

  const handleModalOpen = useCallback(
    (memberEmail: string) => () => {
      setIsModalOpen(true);
      setRemovedMemberEmail(memberEmail);
    },
    [],
  );

  const handleMemberRemove = useCallback(async () => {
    try {
      await subscriptionsApi.removeMemberFromSubscription(removedMemberEmail, clientId);

      setNotificationOpen(true);
      setNotificationType('success');
      setNotificationMessage('The user has been removed successfully');

      setSubscription((subscription) =>
        subscription
          ? {
              ...subscription,
              subscriptionDetails: {
                ...subscription.subscriptionDetails,
                users: subscription.subscriptionDetails.users.filter(({ email }) => email !== removedMemberEmail),
              },
            }
          : undefined,
      );
    } catch (e) {
      setNotificationOpen(true);
      setNotificationType('error');
      setNotificationMessage(e instanceof Error ? e.message : 'Unexpected error');
    } finally {
      handleModalClose();
    }
  }, [clientId, handleModalClose, removedMemberEmail, subscriptionsApi, user]);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
  };

  return (
    <>
      {!users.length ? (
        <Box>
          <Typography align="center">No members associated to this subscription. Please use the field above to add members.</Typography>
        </Box>
      ) : (
        <>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHead}>Name</TableCell>
                <TableCell className={classes.tableCellHead}>Email</TableCell>
                <TableCell className={classes.tableCellHead}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((member: SDSUser, id) => (
                <TableRow key={id}>
                  <TableCell className={classes.tableCellBody}>{member.name}</TableCell>
                  <TableCell className={classes.tableCellBody}>{member.email}</TableCell>
                  <TableCell className={classes.tableCellBody} width={50} align="center">
                    <Tooltip title={member.email === loggedUserEmail ? 'You cannot delete your own user identity' : 'Delete'}>
                      <span>
                        <IconButton
                          disabled={member.email === loggedUserEmail}
                          aria-label="close"
                          color="inherit"
                          onClick={handleModalOpen(member.email)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
        <ConfirmationModal
        open={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleMemberRemove}
        title='Delete a user from this subscription'
        question='Are you sure you want to delete this user?'
        note="This user will lose access to the subscription information."
        confirmText='Delete user'
      >
        <Box mt={4} mb={3}>
          <ColoredText>{removedMemberEmail}</ColoredText>
        </Box>
      </ConfirmationModal>
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </>
  );
};
