import React, { FC } from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core';
import { useSubscription } from '../../../../../../hooks';
import { useStyles } from './styles';

export const DetailsTab: FC = () => {
  const classes = useStyles();
  const { subscription } = useSubscription();

  if (!subscription) return null;

  const {
    subscriptionDetails: { connectedApis },
  } = subscription;

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Connections associated to this subscription
        </Typography>
        {connectedApis.length ? (
          <Table className={classes.table} aria-label="connected APIs">
            <TableHead>
              <TableRow>
                <TableCell>Product API Name</TableCell>
                <TableCell>Business Service Name</TableCell>
                <TableCell>APM Id</TableCell>
                <TableCell>Authorisation Server Id</TableCell>
                <TableCell>Environment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedApis.map(
                ({
                  name,
                  businessServiceName,
                  apmId,
                  authorizationServerId,
                  environmentType,
                }) => (
                  <TableRow key={authorizationServerId}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{businessServiceName}</TableCell>
                    <TableCell>{apmId}</TableCell>
                    <TableCell>{authorizationServerId}</TableCell>
                    <TableCell>{environmentType}</TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        ) : (
          <Typography color="textSecondary">Not available</Typography>
        )}
      </Box>
    </>
  );
};
