import React, { FC, PropsWithChildren } from 'react';
import { useMatch } from 'react-router';
import { Routes } from '../../constants/routes';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { PageLayoutWrapper } from './PageLayoutWrapper/PageLayoutWrapper';

export const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const isNotFoundRoute = useMatch(Routes.NOT_FOUND);

  return (
    <PageLayoutWrapper>
      <Header />
      {children}
      {!isNotFoundRoute && <Footer />}
    </PageLayoutWrapper>
  );
};
