export namespace BuildTarget {
    export const SC = 'service-catalogue';
    export const DP = 'dev-portal';
}

export namespace DocumentType {
    export const SUBSCRIBED = 'subscribed';
    export const NON_SUBSCRIBED = 'non-subscribed';
    export const TRIAL = 'trial';
}

export enum AudienceType {
    INTERNAL = 'internal',
    EXTERNAL = 'external'
}