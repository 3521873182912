import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext } from 'react';
import { SubscriptionDetails } from '@internal/plugin-eapi-common';

export interface ISubscriptionContext {
  subscription: SubscriptionDetails | undefined;
  setSubscription: Dispatch<SetStateAction<SubscriptionDetails | undefined>>;
}

export interface SubscriptionContextProps {
  subscriptionContext: ISubscriptionContext;
}

export const SubscriptionContext = createContext<ISubscriptionContext>({
  subscription: undefined,
  setSubscription: () => { },
});

export const SubscriptionContextProvider = ({ subscriptionContext, children }: PropsWithChildren<SubscriptionContextProps>) => (
  <SubscriptionContext.Provider value={subscriptionContext} children={children} />
);

export const useSubscription = () => useContext(SubscriptionContext);
