import * as yup from 'yup';
import { FieldLabels, FieldNames } from '../../../../../types';
import { EMAIL_REGEX, NO_HTML_REGEX, getErrorMessage } from '../../../../../../../../constants/validation';

export const schema = yup.object().shape({
  [FieldNames.BUSINESS_EMAIL]: yup
    .string()
    .required(getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'required'))
    .matches(EMAIL_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'email'))
    .matches(NO_HTML_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'html'))
    .transform((value) => value.trim()),
});
