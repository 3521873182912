import axios from "axios";
import { useEffect } from "react";
import { useAuth } from "../context/useAuth";
import { useRefreshToken } from "../useRefreshToken";

export const useAxiosPrivate = () => {
    const { user } = useAuth();
    const { refresh } = useRefreshToken();

    useEffect(() => {

        const requestIntercept = axios.interceptors.request.use(
            config => {
                if (config.url?.includes('/user/signup')) { 
                    return config;
                }
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${user?.identity.token}`;
                }

                if (!config.headers['Authorization-Okta']) {
                    config.headers['Authorization-Okta'] = `Bearer ${user?.oktaAccessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = axios.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?._retry) {
                    prevRequest._retry = true;
                    const newUserSession = await refresh();
                    const newBackstageAccessToken = newUserSession?.identity.token;
                    const newOktaAccessToken = newUserSession?.oktaAccessToken;
                    prevRequest.headers['Authorization'] = `Bearer ${newBackstageAccessToken}`;
                    prevRequest.headers['Authorization-Okta'] = `Bearer ${newOktaAccessToken}`;
                    return axios(prevRequest);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.request.eject(requestIntercept);
            axios.interceptors.response.eject(responseIntercept);
        }
    }, [user])

    return axios;
};