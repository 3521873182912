import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Hidden } from '@mui/material';
import { VideoBackground } from './components/VideoBackground';
import { HeroContent } from './components/HeroContent/HeroContent';
import { useStyles } from './styles';

const MAX_WIDTH = 1508;

export const Hero = () => {
  const classes = useStyles();

  return (
    <>
      <VideoBackground videoSourceName="apiPlatform.mp4">
        <Box
          maxWidth={MAX_WIDTH}
          marginX="auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          className={classes.overlaidContent}
        >
          <Typography gutterBottom variant="h2" component='h1'>
            Swiss Re {' '}
            <Hidden smUp><br/></Hidden>
            Developer Portal
          </Typography>
          <Typography variant="h3">Join us in driving digital transformation in the insurance industry</Typography>
        </Box>
      </VideoBackground>
      <Box maxWidth={MAX_WIDTH} marginX="auto">
        <HeroContent />
      </Box>
    </>
  );
};
