import React, { useCallback, useState } from 'react';

import { useApi } from '@backstage/core-plugin-api';

import { TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FieldProps } from '@rjsf/utils';
import { mdmApiRef } from '../api';
import { debounce, DEBOUNCE_DELAY } from '../utils';
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab';


export const APMIDValidatorExtension = ({
    onChange,
    rawErrors,
    formData,
    schema,
    required,
    name
}: FieldProps<string>) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);

    const MDMApi = useApi(mdmApiRef);

    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);

    const handleInputChange = useCallback(async (_event: object, value: string, reason: AutocompleteInputChangeReason) => {
        if (value.length > 2 && reason === 'input') {
            setLoading(true);
            const values = await MDMApi.fetchAPMIDs(value).then(res => res?.values);
            values && setOptions(values);
            setLoading(false);
        } else {
            setOptions([]);
        }
    }, []);

    const handleValueChange = useCallback((_event: object, value: string | null, _reason: string) => {
        setOptions([]);
        value && onChange(value);
    }, []);


    return (
        <Autocomplete
            id={name}
            noOptionsText="No APMIDs found"
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            onInputChange={debounce(handleInputChange, DEBOUNCE_DELAY)}
            onChange={handleValueChange}
            options={options}
            value={formData ?? ''}
            loading={loading}
            loadingText="Fetching data..."
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={schema.title}
                    error={rawErrors && rawErrors.length > 0}
                    helperText={!formData && "Type 3 and more characters to start searching"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};