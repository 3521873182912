import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  summaryData: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[50],
  },
}));
