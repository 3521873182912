import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { KeyStatus as KeyStatusEnum } from '@internal/plugin-eapi-common';
import { useStyles } from './styles';

export interface KeyStatusProps {
  status: KeyStatusEnum;
}

const STATUS_TEXT_MAP = {
  [KeyStatusEnum.Active]: 'Active',
  [KeyStatusEnum.Inactive]: 'Inactive',
} as const;

export const KeyStatus: FC<KeyStatusProps> = ({ status }) => {
  const classes = useStyles({ status });

  return (
    <Box display="flex" alignItems="center" gridColumnGap={6}>
      <span className={classes.indicator}></span>
      {STATUS_TEXT_MAP[status]}
    </Box>
  );
}