import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, TextFieldProps, TextField } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { getEntityTitle } from '@internal/plugin-eapi-common';
import { filterNullValues } from '../../../utils';
import isEmpty from 'lodash/isEmpty';
import { Autocomplete } from '@material-ui/lab';

/*
 This is the actual component that will get rendered in the form
*/
export interface EntitySelectFieldInterface {
  name: string;
  label: string;
}

export const EntitySelectExtension = (
  props: FieldExtensionComponentProps<EntitySelectFieldInterface>,
) => {
  const {
    onChange,
    rawErrors,
    errorSchema,
    required,
    formData,
    uiSchema,
    schema,
  } = props;

  const handleChange = (
    _: ChangeEvent<{}>,
    newValue: any
  ) => {
    onChange(newValue);
    newValue !== null ? setValue(newValue) : setValue({});
  };

  const catalogApi = useApi(catalogApiRef);
  const [services, setServices] = useState<any>();
  const [value, setValue] = useState<string | string[] | null | {}>();

  useEffect(() => {
    const entityKind = uiSchema['ui:options']?.entityKind as string;
    async function getServices() {
      const response = await catalogApi.getEntities({
        filter: { kind: entityKind },
      });

      const servicesList: Array<{ label: string; name: string }> =
        response.items.map(item => {
          return { label: getEntityTitle(item), name: item.metadata.name };
        }).sort((a, b) => a.label?.localeCompare(b.label));
      const formDataObject = servicesList.find(item => {
        return item.name === formData?.name;
      });

      if (!isEmpty(formDataObject)) {
        onChange(formDataObject);
      }

      setValue(formDataObject);
      setServices(filterNullValues(servicesList));
    }
    getServices();
  }, [formData]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        options={services || []}
        getOptionLabel={(option: any) => option.label || ''}
        value={(value as string) || ''}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
          return (
            <TextField
              error={errorSchema && Object.keys(errorSchema).length > 0 && isEmpty(value)}
              {...params}
              variant="standard"
              label={schema.title}
              helperText={errorSchema &&
                Object.keys(errorSchema).length > 0 && isEmpty(value)
                ? 'is a required property'
                : schema.description
              }
              required
            />
          );
        }}
        onChange={handleChange}
        closeIcon={null}
      />
    </FormControl>
  );
};
