import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '100%',

    [theme.breakpoints.up('md')]: {
      minWidth: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '40%',
    },
  },
}));
