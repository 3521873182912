import React, { FC } from 'react';
import { makeStyles, Theme, Box, Link, Divider, Typography, Container } from "@material-ui/core";
import Linkedin from "../../components/Icons/Linkedin";
import Twitter from "../../components/Icons/Twitter";
import Youtube from "../../components/Icons/Youtube";
import Instagram from "../../components/Icons/Instagram";
import { Links } from "../../constants/links";

const useStyles = makeStyles<Theme>(
  theme => ({
    footer: {
      flexShrink: 0,
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.grey[50],
    },
    subFooter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column-reverse',
      gap: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },

      [theme.breakpoints.up('xl')]: {
      },
    },
    linksList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      textAlign: 'center',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row'
      },
    },
    socialLinksList: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    socialLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 51,
      height: 51,
      border: `1px solid ${theme.palette.grey[100]}`,
      borderRadius: '50%',
      backgroundColor: theme.palette.background.default,
      transition: 'border-color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s, transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s, -webkit-transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s',

      '&:hover': {
        borderColor: theme.palette.grey[700]
      },
    },
    copyright: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },

      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
  }),
  { name: 'DeveloperPortalFooter' },
);


export const Footer: FC = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl" className={classes.subFooter}>
        <Box className={classes.linksList}>
          <Link variant="body1" href={Links.PRIVACY_POLICY} target="_blank">About Privacy</Link>
          <Link variant="body1" href={Links.TERMS_OF_USE} target="_blank">Terms of Use</Link>
          <Link variant="body1" href={Links.SLAVERY_STATEMENT} target="_blank">UK Slavery statement</Link>
        </Box>
        <Box className={classes.socialLinksList}>
          <Link className={classes.socialLink} href={Links.LINKEDIN} target="_blank" rel="noopener">
            <Linkedin />
          </Link>
          <Link className={classes.socialLink} href={Links.TWITTER} target="_blank" rel="noopener">
            <Twitter />
          </Link>
          <Link className={classes.socialLink} href={Links.YOUTUBE} target="_blank" rel="noopener">
            <Youtube />
          </Link>
          <Link className={classes.socialLink} href={Links.INSTAGRAM} target="_blank" rel="noopener">
            <Instagram />
          </Link>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="xl" className={classes.copyright}>
        <Typography variant="body1">&copy; {currentYear} Swiss Re Developer Portal</Typography>
        <Typography variant="body1">All rights reserved.</Typography>
      </Container>
    </footer>
  );
}