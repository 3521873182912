import React, { FC } from 'react';
import { useStyles } from './styles';

export const FolderIcon: FC = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} width={40} height={36} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4 29.329c0 .65.077 1.28.172 1.912H4.204a3.822 3.822 0 0 1-3.82-3.825V4.47A3.81 3.81 0 0 1 4.203.645h11.464l3.822 3.824h15.285a3.822 3.822 0 0 1 3.821 3.825v11.11a11.862 11.862 0 0 0-3.821-1.377V8.294H4.205v19.122h17.367c-.095.631-.172 1.262-.172 1.913Zm15.935-6.043-6.86 6.865-3.037-3.04-2.217 2.218 5.255 5.736 9.075-9.083-2.216-2.696Z"
        fill="#00AA91"
      />
    </svg>
  );
};
