import React, { FC } from 'react';
import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    width: 22,
    height: 22,
    fill: 'none',
  },
  path: {
    fill: theme.palette.grey[600],
  },
}));

const Twitter: FC = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className={classes.path} d="M13.093 9.316 21.283 0h-1.94L12.23 8.089 6.55 0H0l8.589 12.231L0 22h1.94l7.51-8.542L15.45 22H22L13.093 9.316Zm-2.658 3.023-.87-1.218L2.64 1.43h2.981l5.588 7.82.87 1.219 7.264 10.166h-2.98l-5.928-8.296Z" />

    </svg>
  );
};

export default Twitter;