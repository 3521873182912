import { Box, makeStyles, Typography } from '@material-ui/core';
import { Avatar } from '@backstage/core-components';
import React from 'react';
import { Navigate } from 'react-router';
import { useAuth } from '../../hooks';
import { SupportMenu } from "./components/SupportMenu";
import { ProfileMenu } from "./components/ProfileMenu";

const avatarCustomStyles = { width: 40, height: 40 }

const useStyles = makeStyles({
  parent: {
    width: '100%',
    whiteSpace: 'nowrap'
  },
  user: {
    fontSize: "15px",
    fontWeight: 400
  }
})

export const Profile = () => {
  const { user } = useAuth();
  const classes = useStyles();

  if (!user) {
    return <Navigate to="/" />;
  }

  const userName = user.profile?.displayName?.split(' ')[0] || "Human"

  return (
    <Box display="flex" alignItems="center" gridGap={6}>
      <SupportMenu />
      <Avatar
        customStyles={avatarCustomStyles}
        displayName={userName}
        picture="user.profile?.picture"
      />
      <Typography className={classes.user}>Hello, {userName}!</Typography>
      <ProfileMenu />
    </Box>
  );
};