import { FieldValidation } from '@rjsf/utils';
import { EmailFieldInterface } from './EmailValidatorExtension';

export const emailPickerValidation = (
  value: EmailFieldInterface,
  validation: FieldValidation
) => {
    if(!value.validity) {
      const error = value.errorMessage ?? (value.text ? 'Email address does not exist in our system':'is a required property');
      validation.addError(error);
    }
};