import React from 'react';
import { Box, Container } from '@material-ui/core';
import { Hero } from './components/Hero';
import { OurApis } from './components/OurApis';
import { DeveloperResources } from './components/DeveloperResources';
import { CustomerTestimonials } from './components/CustomerTestimonials';
import { ExploreSignUp } from './components/ExploreSignUp';
import { SignUpDialog } from '../../components/SignUp/SignUpDialog';

export const Home = () => {

  return (
      <Box flex="1 0 auto">
        <Hero />
        <Container maxWidth="lg">
          <OurApis />
        </Container>
        <Container maxWidth="xl">
          <CustomerTestimonials />
          <DeveloperResources />
        </Container>
        <ExploreSignUp />
        <SignUpDialog />
      </Box>
  );
};
