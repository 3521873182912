import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { BookIcon } from '@internal/plugin-eapi-react';
import Typography from '@material-ui/core/Typography';
import { LinkDetails } from '../../types';
import { useStyles } from './styles';

export interface LinksBoxProps {
  links: LinkDetails[];
}

export const LinksBox: FC<LinksBoxProps> = ({ links }) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper} px={2} py={1}>
      <Box className={classes.listHeading}>
        <BookIcon /><Typography variant='body2'><b>Guides</b></Typography>
      </Box>
      <List dense>
        {links.map(({ displayText, path }) => (
          <ListItem key={path} disableGutters>            
              <Link underline="always" target='_blank' to={path} component={RouterLink} className={classes.link}>
                {displayText}
                <OpenInNewIcon fontSize="small" />
              </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
