import React, {FC} from 'react';
import {makeStyles, Theme} from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  svg:{
    width: 27,
    height: 18,
    fill: 'none'
  },
  path: {
    fill: theme.palette.grey[600],
  },
}));

const Youtube: FC = () => {
  const classes = useStyles();

  return (
      <svg
          className={classes.svg}
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            className={classes.path}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.901 1.32c.74.79 1.003 2.533 1.003 2.533s.264 2.058.264 4.17v1.9c0 2.059-.264 4.117-.264 4.117s-.264 1.742-1.003 2.534c-.858.905-1.845.993-2.378 1.04l-.155.015c-3.537.264-8.868.264-8.868.264s-6.598-.053-8.604-.264c-.09-.016-.199-.03-.32-.046-.65-.084-1.676-.217-2.477-1.062-.74-.792-1.003-2.534-1.003-2.534S.832 11.93.832 9.87v-1.9c0-2.059.264-4.117.264-4.117S1.36 2.11 2.099 1.319C2.957.413 3.944.326 4.477.28c.057-.006.11-.01.155-.016C8.17 0 13.5 0 13.5 0s5.331 0 8.868.263c.046.006.098.01.155.015.533.048 1.52.135 2.378 1.041ZM10.86 5.065v7.126l6.862-3.537-6.862-3.589Z"
      />
      </svg>
  );
};

export default Youtube;