import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  alert: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[50],
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  multiline: {
    border: `1px solid ${theme.palette.grey[100]}`,
    borderLeft: `5px solid ${theme.palette.grey[600]}`,
    borderRadius: 0,
  },
  bullet: {
    color: `${theme.palette.common.black}`,
    minWidth: '1rem',
    '& svg': {
      fontSize: '0.5rem',
    },
  },
  listItem: {
    padding: 0,
  },
  icon: {
    color: '#000',
  },
}));
