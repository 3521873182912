import React, { FC } from 'react';
import { Controller, Control } from 'react-hook-form';
import { Card, CardContent, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { FieldNames, SignUpFormData } from '../../constants';
import { useStyles } from './styles';

interface APICardCheckboxProps {
  control: Control<SignUpFormData>;
  id: string;
  name: string;
  description: string;
}

export const APICardCheckbox: FC<APICardCheckboxProps> = ({ control, id, name, description }) => {
  const classes = useStyles();

  return (
    <Controller
      name={`${FieldNames.APIS}.${id}`}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          className={classes.checkboxWrapper}
          control={<Checkbox {...field} checked={field.value} name={`${FieldNames.APIS}.${id}}`} color="primary" />}
          label={
            <Card className={classes.card}>
              <CardContent>
                <Typography className={classes.cardTitle}>
                  {name}
                </Typography>
                <Typography variant="body2">{description}</Typography>
              </CardContent>
            </Card>
          }
        />
      )}
    />
  );
};
