export class EAPIError extends Error {
  public readonly name: string;
  public readonly status: number;
  public readonly description?: string | Record<string, any>;

  constructor(error: any) {
    super();

    this.status = error.status;
    this.name = error.name;
    this.description = error.description;
  }
}

export const EAPI_GENERIC_ERROR = 'Something went wrong, please try again later.';
