import { useAsync } from 'react-use';
import { IGuide } from '../../api';
import { ContainerName } from '@internal/plugin-eapi-common';
import { useEAPIAssetsApi } from '..';

export const useGuidesList = () => {
  const eapiAssetsApi = useEAPIAssetsApi();

  const { value, loading, error } = useAsync(async () => {
    return await eapiAssetsApi.fetchFile<IGuide[]>(ContainerName.SHARED, 'guidesList.json')
  }, []);

  return {
    guidesList: value,
    loading,
    error,
  };
};
