import React, { FC } from "react";
import { Link as RouterLink } from 'react-router-dom';
import {Card as MUICard, CardContent, CardMedia, Typography, makeStyles} from "@material-ui/core";
import CardActionArea from "@mui/material/CardActionArea";

export interface CardProps {
  title: string,
  description: string,
  href: string,
  image?: string,
  altText?: string,
  height?: number | string | undefined,
  component?: React.ElementType
}

const useStyles = makeStyles(theme => ({
  cardContent: {
    minHeight: 216
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

export const Card: FC<CardProps> = (
    {
      image,
      altText,
      title,
      description,
      href,
      component="img",
      height= 150
    }) => {
  const classes = useStyles();

  return (
      <MUICard>
        <CardActionArea to={href} component={RouterLink}>
          {image && <CardMedia
              component={component}
              alt={altText}
              height={height}
              image={image}
          />}
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body1">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </MUICard>
  )
}