import { discoveryApiRef, useApi } from "@backstage/core-plugin-api";
import { Subscription, SubscriptionDetails } from "@internal/plugin-eapi-common";
import { useCoreApi } from "../useCoreApi";

export const useSubscriptionApi = () => {
    const { doGet, doPut, doDelete } = useCoreApi();
    const discoveryApi = useApi(discoveryApiRef);

    const getSubscriptions = async (): Promise<Subscription[]> => {
        try {
            const url = await discoveryApi.getBaseUrl(`subscriptions`);
            const response = await doGet<Subscription[]>(url);
            return response.data;
        } catch (error: any) {
            console.error('Get Subscriptions client call error :: ' + error.message);
            throw error;
        }
    }

    const getSubscriptionByClientId = async (clientId: string): Promise<SubscriptionDetails> => {
        try {
            const url = await discoveryApi.getBaseUrl(`subscriptions/${clientId}`);
            const response = await doGet<SubscriptionDetails>(url);
            return response.data;
        } catch (error: any) {
            console.error('Get SubscriptionData details client call error :: ' + error.message);
            throw error;
        }
    }

    const addMemberToSubscription = async (businessEmail: string, clientId: string) => {
        try {
            const url = await discoveryApi.getBaseUrl(`subscriptions/add`);
            const body = {
                clientId: clientId,
                email: businessEmail,
            };
            const response = (await doPut(url, body)) as any;
            return response.data;
        } catch (error: any) {
            console.error('Add member form call error :: ' + error.message);
            throw error;
        }
    }

    const removeMemberFromSubscription = async (memberEmail: string, clientId: string) => {
        try {
            const url = await discoveryApi.getBaseUrl(`subscriptions/remove`);
            const body = {
                clientId: clientId,
                email: memberEmail,
            };
            const response = (await doDelete(url, body)) as any;
            return response.data;
        } catch (error: any) {
            console.error('Remove member call error :: ' + error.message);
            throw error;
        }
    }

    return { getSubscriptions, getSubscriptionByClientId, addMemberToSubscription, removeMemberFromSubscription }
}