import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  makeStyles,
  Button,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubscriptionDetails } from '@internal/plugin-eapi-common';
import { schema } from './schema';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useSubscription } from '../../../../../../../../hooks';
import { useSubscriptionApi } from '../../../../../../../../hooks';
import { MembersFormData, FieldNames, FieldLabels } from '../../../../../types';
import { AxiosError } from 'axios';

const useStyles = makeStyles(
  theme => ({
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }),
  { name: 'DeveloperPortalMembersForm' },
);

export const MembersForm: FC = () => {
  const classes = useStyles();
  const { subscription, setSubscription } = useSubscription();
  const { register, handleSubmit, formState, reset } = useForm<MembersFormData>(
    {
      mode: 'all',
      resolver: yupResolver(schema),
    },
  );
  const subscriptionsApi = useSubscriptionApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<'error' | 'success'>(
    'success',
  );
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  const { errors } = formState;

  if (!subscription) return null;

  const onSubmit = async (data: MembersFormData) => {
    const {
      subscriptionDetails: { users, clientId },
    } = subscription;

    try {
      const isMemberExist = users.some(
        ({ email }) => data[FieldNames.BUSINESS_EMAIL] === email,
      );

      if (isMemberExist) {
        throw new Error(
          'The user has already been added to the members list. Please try a different business email.',
        );
      }

      setLoading(true);
      const businessEmail = data[FieldNames.BUSINESS_EMAIL];
      await subscriptionsApi.addMemberToSubscription(businessEmail, clientId);

      setNotificationOpen(true);
      setNotificationType('success');
      setNotificationMessage('The user has been added successfully');

      const updatedSubscription: SubscriptionDetails = await subscriptionsApi.getSubscriptionByClientId(clientId);
      setSubscription(updatedSubscription);
    } catch (error) {
      setNotificationOpen(true);
      setNotificationType('error');
      const errorMessage = (error instanceof AxiosError)
        ? error.response?.data?.message
        : 'A problem has  occurred, please try again or contact the Developer Portal team if the error persists.';
      setNotificationMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
    reset();
  };

  return (
    <Box
      width={{
        xs: '100%',
        md: '50%',
        lg: '40%',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          fullWidth
          variant="outlined"
          label="Business email"
          type="email"
          margin="normal"
          required
          {...register(FieldNames.BUSINESS_EMAIL)}
          error={Boolean(errors[FieldNames.BUSINESS_EMAIL])}
          helperText={errors[FieldNames.BUSINESS_EMAIL]?.message}
          inputProps={{ 'aria-label': FieldLabels.BUSINESS_EMAIL }}
        />

        <Button
          type="submit"
          className={classes.button}
          variant="contained"
          startIcon={loading && <CircularProgress size={24} />}
          disabled={loading || notificationOpen}
        >
          Add user
        </Button>
      </form>
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </Box>
  );
};
