import React, { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export interface TabPanelProps {
  index: number;
  value?: string;
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index }) => {
  const theme = useTheme();
  const { tabName } = useParams();

  if (!value) {
    return null;
  }

  return (
    <Box
      sx={{ borderBottom: `1px solid ${theme.palette.grey[100]}` }}
      py={4}
      role="tabpanel"
      hidden={value !== tabName}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
    >
      {value === tabName && <Box>{children}</Box>}
    </Box>
  );
}
