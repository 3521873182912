import { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { eapiAssetsApiRef } from '@internal/plugin-eapi-react';
import { ContainerName } from '@internal/plugin-eapi-common';
import { FileType } from '../../types';

export const useFetchFile = (kind: string, fileType: FileType, serviceName: string | undefined, fileName: string | undefined) => {
  const [base64File, setBase64File] = useState<string | null>(null);
  const eapiAssetsApi = useApi(eapiAssetsApiRef);

  const { error: getFileError } = useAsync(async () => {
    if(!fileName || !serviceName) return;

    const path = kind.toLowerCase() === 'system' ? `business-services` : `tech-services`;
    
    const blobFile = await eapiAssetsApi.fetchFile(ContainerName.AUTHORED, `${path}/${serviceName}/${fileName}`, fileType);

    const reader = new FileReader();
    reader.readAsDataURL(blobFile);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      setBase64File(base64data);
    };
  }, [serviceName, fileName]);

  if (getFileError) {
    console.error(`Failed to load file, ${getFileError}`);
  }

  return base64File;
};