export function extractMetadata(content: string) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(content, 'text/html');

    const mainCssLink = parsedHtml.querySelector('link[rel="stylesheet"][href*="main"]');
    const href = mainCssLink?.getAttribute('href');
    const cssFileName = href?.split('/').pop();

    const title = parsedHtml.querySelector('title')?.textContent ?? null;
    const description = parsedHtml.querySelector('meta[name="description"]')?.getAttribute('content') ?? null;


    return {cssFileName, title, description};
}