import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { useAuth, useDialog } from '../../../../../../hooks';
import { useStyles } from './styles';
import { useNavigate } from 'react-router';
import { Routes } from '../../../../../../constants/routes';

const HERO_CONTENT_DATA = [
  {
    id: 'hero-text-1',
    title: 'Innovate',
    description: 'Explore the possibilities unlocked by our APIs and leverage our data to drive your business growth.',
  },
  {
    id: 'hero-text-2',
    title: 'Develop',
    description:
      "Access documentation, explore samples, utilise our demo environment, and seamlessly transition to production as soon as you're ready.",
  },
];

const LOGGED_IN_CONTENT = {
  title: 'Ready to explore?',
  description: 'Dive into our solutions and contact us for access.',
  button: 'Explore our APIs',
};
const PUBLIC_CONTENT = { title: 'Ready to start?', description: 'Sign up to access the Swiss Re Developer Portal.', button: 'Sign up now' };

export const HeroContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { handleOpen } = useDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleExplore = () => navigate(Routes.SOLUTIONS);
  const data = user ? { ...LOGGED_IN_CONTENT, action: handleExplore } : { ...PUBLIC_CONTENT, action: handleOpen };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.wrapper} mb={isMobile ? 3 : 0}>
      <Grid container spacing={isMobile ? 10 : 2}>
        {HERO_CONTENT_DATA.map(({ title, description, id }) => (
          <Grid item xs={12} md={4} key={id} className={classes.grid}>
            <Typography variant="h5" gutterBottom className={classes.title}>
              {title}
            </Typography>
            <Typography>{description}</Typography>
          </Grid>
        ))}

        <Grid item container xs={12} md={4} direction="column" className={classes.grid}>
          <Typography variant="h3" gutterBottom>
          {data.title}
          </Typography>
          <Typography gutterBottom>{data.description}</Typography>
          <Button variant="contained" className={classes.button} onClick={data.action}>
            {data.button}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
