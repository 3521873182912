import React, { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ButtonProps, Link } from '@material-ui/core';
import { useStyles } from './styles';

export interface LinkButtonProps extends Omit<ButtonProps, 'variant'> {
  navigateUrl: string;
  target?: string;
  variant?: 'primary' | 'secondary';
  width?: string
}

export const LinkButton = ({ navigateUrl, children, target= "_self", variant = 'primary', width = 'fit-content'}: PropsWithChildren<LinkButtonProps>) => {
  const classes = useStyles({variant, width});

  return (
    <Link to={navigateUrl} component={RouterLink} className={classes.link} target={target}>
      {children}
    </Link>
  );
};
