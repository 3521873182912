import React, { FC, RefObject, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { useAsync } from 'react-use';
import { LinearProgress } from '@material-ui/core';
import { ContainerName } from '@internal/plugin-eapi-common';
import { useEAPIAssetsApi } from '../../hooks';
import { handleAnchorClick, removeUnwantedElements } from './utils';
import { selectorsToIgnore } from './constants';
import { ImageMessage } from '../ImageMessage';
import { EAPITechDocsMetadata } from '@internal/plugin-eapi-react';

export interface TechDocsHTMLProps {
  content: string;
  metadata: EAPITechDocsMetadata;
}

export const TechDocsHTML: FC<TechDocsHTMLProps> = ({ content, metadata }) => {
  const eapiAssetsApi = useEAPIAssetsApi();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const shadowRootRef = useRef<ShadowRoot | null>(null);
  const styleElementsRef = useRef<HTMLStyleElement[]>([]);

  // Initialize shadow DOM on component mount
  useEffect(() => {
    if (!containerRef.current) return;

    shadowRootRef.current = containerRef.current.attachShadow({ mode: 'open' });
  }, []);

  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      styleElementsRef.current.forEach((styleElement) => {
        if (styleElement.parentNode) {
          styleElement.parentNode.removeChild(styleElement);
        }
      });
      styleElementsRef.current = [];
      shadowRootRef.current = null;
    };
  }, []);

  // Sanitize and update the content within shadow DOM
  useEffect(() => {
    if (!(content && shadowRootRef.current)) return;

    DOMPurify.addHook('uponSanitizeAttribute', (node, data) => {
      // set all elements owning target to target=_blank
      if ('target' in node && data.attrName === 'target' && data.attrValue === '_blank') {
        data.allowedAttributes['target'] = true;
      }
    });

    const shadowRoot = shadowRootRef.current;
    const sanitizedContent = DOMPurify.sanitize(content);
    // Parse the sanitized content into a temporary container
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = sanitizedContent;
    // Remove unwanted elements from the content
    removeUnwantedElements(tempContainer, selectorsToIgnore);
    // Set the modified content to the shadow DOM
    shadowRoot.innerHTML = tempContainer.innerHTML;
    // Add event listener for anchor links
    const handleAnchorClickWithShadowDom = (event: Event) => handleAnchorClick(event, shadowRoot);

    shadowRoot.addEventListener('click', handleAnchorClickWithShadowDom);

    return () => {
      if (shadowRoot) {
        shadowRoot.removeEventListener('click', handleAnchorClickWithShadowDom);
      }
    };
  }, [content]);

  // Fetch and inject CSS into the shadow DOM
  const injectCSS = async () => {
    if (!shadowRootRef.current) return;

    try {
      const mainCSSFilePath = `default/system/${metadata.fileName}/assets/stylesheets/${metadata.cssFileName}`;
      const mainCSSData = await eapiAssetsApi.fetchFile<Blob>(ContainerName.EAPI_TECH_DOCS, mainCSSFilePath);
      let mainCSSText = await mainCSSData.text();
      // Replace :root with :host in the main CSS to make all variables scoped to the shadow DOM
      mainCSSText = mainCSSText.replace(/:root/g, ':host');

      const mainCSSstyleElement = document.createElement('style');
      mainCSSstyleElement.textContent = mainCSSText;

      const customCSSFilePath = `styles/tech-docs-custom.css`;
      const customCSSData = await eapiAssetsApi.fetchFile<Blob>(ContainerName.SHARED, customCSSFilePath);
      const customCSSstyleElement = document.createElement('style');
      customCSSstyleElement.textContent = await customCSSData.text();

      shadowRootRef.current.appendChild(mainCSSstyleElement);
      shadowRootRef.current.appendChild(customCSSstyleElement);

      styleElementsRef.current.push(mainCSSstyleElement, customCSSstyleElement);
    } catch (error) {
      console.error('Failed to load css files', error);
      throw error;
    }
  };

  // Fetch and inject images and static files within shadow DOM
  const injectAssets = async () => {
    if (!shadowRootRef.current) return;

    const linkElementsWithImgFile = shadowRootRef.current.querySelectorAll<HTMLElement>('img[src^="assets"]');
    const linkElementsWithStaticFile = shadowRootRef.current.querySelectorAll<HTMLElement>('a[href^="assets"]');

    const injectImgFileToHtml = async (linkElement: HTMLElement) => {
      const fileName = linkElement?.getAttribute('src');

      try {
        const filePath = `default/system/${metadata.fileName}/${fileName}`;
        const data = await eapiAssetsApi.fetchFile<Blob>(ContainerName.EAPI_TECH_DOCS, filePath);
        const objectUrl = URL.createObjectURL(data);

        linkElement!.setAttribute('src', objectUrl);
        linkElement!.onload = () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Failed to load img file', error);
      }
    };

    const injectStaticFileToHtml = async (linkElement: HTMLElement) => {
      const fileName = linkElement?.getAttribute('href');

      try {
        const filePath = `default/system/${metadata.fileName}/${fileName}`;
        const data = await eapiAssetsApi.fetchFile<Blob>(ContainerName.EAPI_TECH_DOCS, filePath);
        const objectUrl = URL.createObjectURL(data);

        linkElement!.setAttribute('href', objectUrl);
        linkElement!.onload = () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Failed to load static file', error);
      }
    };

    await Promise.allSettled([
      ...Array.from(linkElementsWithImgFile).map(injectImgFileToHtml),
      ...Array.from(linkElementsWithStaticFile).map(injectStaticFileToHtml),
    ]);
  };

  const { loading, error } = useAsync(async () => {
    if (!content) return;

    await Promise.all([injectCSS(), injectAssets()]);

  }, [content, metadata]);

  if (error) {
    return <ImageMessage type='error' title='Failed to load service documentation' />;
  }

  return (
    <>
      {loading && <LinearProgress />}
      <div style={{ display: loading ? 'none' : 'block' }} data-component="eapi-docs" ref={containerRef as RefObject<HTMLDivElement>} />
    </>
  );
};
