import React, { FC, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { useStyles } from './styles';

export interface ColoredTextProps extends TypographyProps {
  bgColor?: string;
}

export const ColoredText: FC<PropsWithChildren<ColoredTextProps>> = ({ children, bgColor, variant, ...props }) => {
  const classes = useStyles({ bgColor });

  return (
    <Typography variant={variant || "body1"} className={classes.styledText} {...props}>
      {children}
    </Typography>
  );
};
