const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
};

export const formatDate = (stringedDate: string, options: Intl.DateTimeFormatOptions = dateTimeFormatOptions) => {
  const date = new Date(stringedDate);

  return date.toLocaleDateString('default', options);
}

export const formatDateToUTC = (stringedDate: string) => {
  const utcOptions = {...dateTimeFormatOptions, timeZone: "UTC"}
  const date = new Date(stringedDate);

  return date.toLocaleDateString('default', utcOptions)
}