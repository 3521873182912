import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
}));
