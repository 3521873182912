import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@material-ui/core';
import { Section } from '../../../../layout/Section';
import { APIS } from '../../../../constants/apis';
import { useStyles } from './styles';

export const OurApis = () => {
  const classes = useStyles();

  return (
    <Section title="Discover our APIs">
      <Grid container spacing={4}>
        {APIS.map(({ id, name, description, image }) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <Card className={classes.card}>
              <CardMedia height={156} image={image} component="img" alt={name} loading='lazy' />
              <CardContent>
                <Typography variant="h5" className={classes.cardTitle}>
                  {name}
                </Typography>
                <Typography variant="body1">{description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};
