export enum Links {
  SERVICE_NOW = "https://swissrecsm.service-now.com/support/",
  PRIVACY_POLICY= "https://www.swissre.com/privacy-policy.html",
  TERMS_OF_USE = "https://www.swissre.com/terms-of-use.html",
  SLAVERY_STATEMENT = "https://www.swissre.com/about-us/modern-slavery-statement.html",
  LINKEDIN = "https://www.linkedin.com/company/swiss-re/",
  TWITTER = "https://twitter.com/SwissRe",
  YOUTUBE = "https://www.youtube.com/user/swissretv",
  INSTAGRAM = "https://www.instagram.com/swiss_re_group/",
  GOOGLE_PRIVACY_POLICY = "https://policies.google.com/privacy",
  GOOGLE_TERMS = "https://policies.google.com/terms"
}