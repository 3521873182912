import { Api, SDSEnvironmentType } from '@internal/plugin-eapi-common';

export const filterApiListByEnv = (apiList: Api[], env: string) => {
  if (!apiList) {
    return [];
  }

  return apiList.filter((api: Api) => {
    const apiEnv = api.environment && api.environment.toUpperCase();
    if (env === SDSEnvironmentType.PROD) {
      return apiEnv === 'PROD' || apiEnv.includes('-PROD');
    } else {
      return apiEnv === 'NONPROD' || apiEnv.includes('-NP');
    }
  });
};
