import React, { PropsWithChildren, useEffect } from 'react';
import { useApi, configApiRef } from "@backstage/core-plugin-api";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';


const reactPlugin = new ReactPlugin();
    
function AppInsights({ children }: PropsWithChildren<{}>) {
    const configApi = useApi(configApiRef);
    const connectionStringText = configApi.getConfig('swissre.applicationInsights').getString('connectionString');

    useEffect(() => {
        const appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionStringText,
                enableAutoRouteTracking: true,
                extensions: [reactPlugin]
            }
        });
        appInsights.loadAppInsights();
    }, []);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
}

export const AppInsightsWrapper = withAITracking(reactPlugin, AppInsights);

