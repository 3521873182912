import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  svg: {
    fill: 'none',
  },
  primaryPath: {
    fill: '#636363',
  },
  secondaryPath: {
    fill: '#fff',
  },
  cursor: {
    cursor: 'pointer'
  }
});
