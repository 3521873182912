import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: '#FF0000',
  },
}));
