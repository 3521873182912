import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';


export interface TypeFieldInterface {
  text?: string;
  validity?: boolean;
  errorMessage?: string;
  value: string;
}

export const TypeSelectExtension = (props: FieldExtensionComponentProps<TypeFieldInterface>) => {
  const {
    onChange,
    formData,
    schema,
    required,
    rawErrors
  } = props

  return (
    <>
      <FormControl variant='standard'
        required={required}
        error={rawErrors?.length > 0 && !formData}>
        <InputLabel id='type-select-label'>{schema.title}</InputLabel>
        <Select
          value={formData || ''}
          onChange={event => {
            const {
              target: { value },
            } = event;
            onChange(value as TypeFieldInterface)
          }
          }
          id="type-select"
        >
          <MenuItem title={'Support a specific functional step in insurance value chain by orchestrating and aggregating several business services'}
            value='Composite'>
            Composite
          </MenuItem>
          <MenuItem title={'Provide functional and data management capabilities for a specific domain'}
            value='Domain'>
            Domain
          </MenuItem>
          <MenuItem title={'Support a specific business fuhnction (e.g. calculations) but are agnostic of value chain.'}
            value='Enabling'>
            Enabling
          </MenuItem>
          <MenuItem title={'Based on consolidated or derived data from various sources that provide data exchange, reporting and data insight capabilities'}
            value='Reporting and Insights'>
            Reporting and Insights
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

