interface Step {
  label: string;
  title: string;
}

export const STEPS: Readonly<Array<Step>> = [
  {
    label: 'About you',
    title: 'Personal details',
  },
  {
    label: 'APIs',
    title: 'Select the API(s) you are interested in exploring further',
  },
  {
    label: 'Summary',
    title: 'Summary of your request',
  },
];
