import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { Box, Link, TableCell, TableRow } from '@material-ui/core';
import { Api, AuthenticationType, ContainerName, FileType } from '@internal/plugin-eapi-common';
import { SwaggerIcon, PostmanIcon, DownloadIcon } from '@internal/plugin-eapi-react';
import { useEAPIAssetsApi, useSubscription } from '../../../../../../../../hooks';
import { useStyles } from './styles';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';

export interface ApiRowProps {
  api: Api;
  handleSwaggerOpen: (uuid: string) => void;
  handleSwaggerDownload: (uuid: string) => void;
  postmanCollectionPath?: string;
}

export const ApiRow: FC<ApiRowProps> = memo(
  ({ api, handleSwaggerOpen, handleSwaggerDownload, postmanCollectionPath }) => {
    const classes = useStyles();
    const eapiAssetsApi = useEAPIAssetsApi();
    const { subscription } = useSubscription();
  const [isPostmanCollectionDownloaded, setIsPostmanCollectionDownloaded] = useState<boolean>(false);

    const isPublicKeyAuthType = subscription?.subscriptionDetails.authorizationType === AuthenticationType.PUBLIC_KEY;

    const linkRef = useRef<HTMLAnchorElement>(null);

    const handleApiDocClick = () => {
      handleSwaggerOpen(api.uuid);
    };

    const handleApiDownload = () => {
      handleSwaggerDownload(api.uuid);
    };

    const handlePostmanDownload = useCallback(async () => {
      setIsPostmanCollectionDownloaded(true)
      if (linkRef.current!.getAttribute('href')) {
        return;
      }

      try {
        const data = await eapiAssetsApi.fetchFile<Blob>(ContainerName.AUTHORED, `tech-services/${postmanCollectionPath}.${FileType.Zip}`);

        const objectUrl = URL.createObjectURL(data);
        linkRef.current!.setAttribute('href', objectUrl);

        linkRef.current!.click();

        linkRef.current!.onload = () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error('Failed to fetch a file' + error);
      }
    }, [postmanCollectionPath]);

    return (
      <>
      <TableRow>
        <TableCell>{api.displayName}</TableCell>
        <TableCell>{api.version}</TableCell>
        <TableCell>{api.environment}</TableCell>
        <TableCell>
          <Box display="flex" gridColumnGap={20}>
            <Link onClick={handleApiDocClick} underline="always" className={classes.link}>
              <SwaggerIcon />
              View API spec
            </Link>

            <Link onClick={handleApiDownload} underline="always" data-testid="download-button" className={classes.link}>
              <DownloadIcon />
              Download API spec
            </Link>
            {postmanCollectionPath && (
                <Link
                  ref={linkRef}
                  download={`${postmanCollectionPath.replace(/.*\//, '')}.${FileType.Zip}`}
                  underline="always"
                  className={classes.link}
                  onClick={handlePostmanDownload}
                >
                  <PostmanIcon />
                  Download Postman collection
                </Link>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {isPublicKeyAuthType && 
        <NotificationSnackBar 
          variant='standard' 
          open={isPostmanCollectionDownloaded} 
          onClose={() => setIsPostmanCollectionDownloaded(false)} 
          type='info' 
          isMultilineMsg={true}
          message={<span>In the Postman collection, specify the <b>Client ID & Private Key</b> in your “Current” variables. Use the Token Generation collection to call Init and then get token before using any of the API Methods.</span>}
        />
      }
    </>
    );
  },
);

ApiRow.displayName = 'ApiRow';
