import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { SymmetricKey, SubscriptionSymmetricKey, PublicKey } from '@internal/plugin-eapi-common';
import { useCoreApi } from '../useCoreApi';

export const useEncryptionApi = () => {
  const { doGet, doPost, doPut, doDelete } = useCoreApi();
  const discoveryApi = useApi(discoveryApiRef);

  const getSecrets = async (clientId: string): Promise<SymmetricKey[]> => {
    try {
      const url = await discoveryApi.getBaseUrl(`encryption/symmetric/${clientId}`);
      const response = await doGet<SymmetricKey[]>(url);

      return response.data;
    } catch (error: any) {
      console.error('Get Subscription Symmetric Keys list client call error :: ' + error.message);
      throw error;
    }
  };

  const addSecret = async (clientId: string): Promise<SymmetricKey> => {
    try {
      const url = await discoveryApi.getBaseUrl(`encryption/symmetric/add`);
      const body = {
        clientId: clientId,
      };
      const response = await doPost<SymmetricKey>(url, body);
      return response.data;
    } catch (error: any) {
      console.error('Add Symmetric Key client call error :: ' + error.message);
      throw error;
    }
  };

  const deleteSecret = async (id: string, clientId: string): Promise<string> => {
    try {
      const url = await discoveryApi.getBaseUrl(`encryption/symmetric/remove`);
      const body = {
        clientId: clientId,
        secretId: id,
      };
      const response = await doDelete<string>(url, body);
      return response.data;
    } catch (error: any) {
      console.error('Remove Symmetric Key client call error :: ' + error.message);
      throw error;
    }
  };

  //TODO - Change the API endpoints to point to encryption service once its available.
  const getPublicKeys = async (clientId: string): Promise<PublicKey> => {
    try {
      // const url = await discoveryApi.getBaseUrl(`encryption/asymmetric/${clientId}/keys`);
      const url = await discoveryApi.getBaseUrl(`subscriptions/${clientId}/keys`);
      const response = await doGet<PublicKey>(url);
      return response.data;
    } catch (error: any) {
      console.error('Get public keys call error :: ' + error.message);
      throw error;
    }
  };

  //TODO - Change the API endpoints to point to encryption service once its available.
  const setPublicKey = async (clientId: string, publicKey: string): Promise<string> => {
    try {
      // const url = await discoveryApi.getBaseUrl(`encryption/asymmetric/key`);
      const url = await discoveryApi.getBaseUrl(`subscriptions/key`);
      const body = {
        clientId: clientId,
        publicKey: publicKey,
      };
      const response = await doPut<string>(url, body);
      return response.data;
    } catch (error: any) {
      console.error('Set public keys call error :: ' + error.message);
      throw error;
    }
  };

  //TODO - Temporary solution, to fallback to old UI until all subscriptions gets migrated to ECSP
  const getLegacySecret = async (clientId: string): Promise<SubscriptionSymmetricKey> => {
    try {
      const url = await discoveryApi.getBaseUrl(`subscriptions/${clientId}/secret`);
      const response = await doGet<SubscriptionSymmetricKey>(url);
      return response.data;
    } catch (error: any) {
      console.error('Get Subscription Symmetric Key details client call error :: ' + error.message);
      throw error;
    }
  };

  return { getSecrets, getPublicKeys, setPublicKey, addSecret, deleteSecret, getLegacySecret };
};
